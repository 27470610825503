.create-img {
    height: 20px ;
}
.filter-img{
    height: 20px ;
}
.creating {
    float: left;
    margin-top: 0%;

}
.filtering {
    display: none;
    float: right;
    margin-top: 0%;
    margin-right:56%;
}
.create {
    /* margin-top: -4%; */
    margin-top: 0%;
}
.btn {
    margin-top: 10px;
    margin-bottom: 30px;
}
.action-description{
    width: 50% !important;
}
.reduce-width{
    width: 10% !important;
}
.notes-width{
    width: 80% !important;
    word-break: break-all;
}
