.sidebar-section {
    height: 100%;
    width: 17%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 10px;
}

.sidebar-section .sidebar {
    width: 245px;
    position: fixed;
    left: 0;
    padding: 102px 0rem .5rem;
    height: 100%;
    overflow: hidden;
    z-index: 19;
    background-color: black;
    @media screen and ( min-width: 900px ) and ( max-width: 1300px ) {
        width: 220px;
      }
    @media screen and ( min-width: 1300px ) and ( max-width: 1470px ) {
      
        width: 245px;
      }
      @media screen and ( min-width: 1400px ) and ( max-width: 1700px ) {
        width: 250px;
      }
}

.sidebar-section .sidebar .scrollbar-inner {
    position: relative;
    height: 100%;

}

.sidebar-section .sidebar .scrollbar-inner .customDropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown .button {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown .button svg {
    margin-left: 45%;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content {
    display: none;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
}


.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content>.link {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown .unhide {
    display: block;
}

.sidebar-section .sidebar .scrollbar-inner .customDropdown:hover .button {
    background-color: #818181;
}


.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content>.link:hover {
    background-color: #ddd;
}


.sidebar-section .sidebar .scrollbar-inner .navigation {
    padding: 0;
    list-style: none;
}

.sidebar-section .sidebar .scrollbar-inner .navigation a:hover {
    text-decoration: none;
}

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item {
    font-size: 16px;
    padding: 10px 15px;
    color: white;
}

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item a {
    text-decoration: none;
    color: white;
}

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item:hover {
    text-decoration: none !important;
    background-color: #818181 !important;
    cursor: pointer;
}

.list-item {
    color: white;
    background-color: #020202;
}

.dropbtn {
  background-color: #000000;
  min-width: 240px;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.sidebar-dropdown-header{
  position: relative;
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 0px;
  right: 50;
  background-color: #f9f9f9;
  min-width: 160px;
  text-align:center;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: 100px;
}


.dropdown-content a {
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #818181 !important;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;}

.sidebar-dropdown-header:hover .dropdown-content {
  display: block;
}

.sidebar-dropdown-header:hover .dropbtn {
  background-color: #818181;
}
