.form-modal.modal-dialog {
  max-width: 95%;
}
.hoaForm.modal-dialog {
  max-width: 95%;
}

.add-management-company {
  display: flex;
  justify-content: space-between;

  .add-model-left-portion {
    width: 55%;
    padding: 10px;
  }

  .add-model-right-portion {
    width: 45%;
    padding: 10px;

    .note{
        font-size: 10px;
    }
    .add-poc {
      color: white;
      display: flex;
      border: 1px solid white;
      background: #53bf53;
      border-radius: 20px;
      width: 25%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .text {
        padding: 8px;
      }
    }
    .code {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0px;
      margin-top: 0px;
      padding-right: 10px;
      width: 90%;
      
    .question {
      display: inline-flex;
      // justify-content: flex-end;
      width: 50%;
      .rightside-content {
        padding-left: 25px;
      }
      .radio {
        display: inline-flex;
        .custom-control {
          margin-right: 0px;
        }
      }
      .text {
        width: 100%;
        text-align: right;
      }
    }    
  }
  .question {
    display: inline-flex;
    // justify-content: flex-end;
    width: 88%;
    .rightside-content {
      padding-left: 25px;
    }
    .radio {
      display: inline-flex;        
      .custom-control {
        margin-right: 0px;
      }
    }
    .text {
      width: 100%;
      text-align: right;
    }
  } 
  
  .noDocument {
    margin-top: 10px;
    text-align: right;
   // margin-right: 2.5rem;
  }  
  
  .file-btn{
    margin-left: 10rem;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  } 
}
}
.management-company-poc{
  font-weight: bold;
  text-align: end;
  font-size: 15px!important;
  margin-top: -10px;
}
.poc-style {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 20px;
}

.add_assessment {
  display: flex;
  flex-wrap: wrap;
  .fy_assessment {
    border: 1px solid lightgrey;
    padding: 10px;
    width: 48%;
    margin: 8px;
  }
}
.add_fy_Assesment{
  color: white;
  display: flex;
  border: 1px solid white;
  background: #53bf53;
  border-radius: 20px;
  width: 20%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height:0%;
  .text {
    padding: 8px;
  }
}
.address-body{
  border: 1px solid lightgray;
  padding:15px 10px 0px 10px;
  margin:10px 0px 0px;
}

.address-name{
  border-radius: 0px 15px  15px 0px!important;
}

.rdg-editor-container { z-index: 10051 !important; }
.add_New_Assessment {
  color: white;
  display: flex;
  border: 1px solid white;
  background: #53bf53;
  border-radius: 20px;
  width: 13%;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}
.add_New_Assessment.disable{
  pointer-events: none;
  opacity: 0.5;
}
.assessment_type {
  font-weight: bold;
  margin-top: 1%;
  font-size: 17px;
  color: #ff8f00c4;
}
.add_line_item {
  color: white;
  display: flex;
  border: 1px solid white;
  background: #6c757d;
  border-radius: 20px;
  width: 200%;
  align-items: center;
  justify-content: center;
  margin-top: -2%;
  margin-left: 85%;
  padding: 8px;
}
.table_allignment {
  margin-top: 1%;
}
.new_assessment {
  margin-top: 1%;
  min-width: 30%;
    margin-right: 10px;
}
.add_address {
  display: flex;
}

.add-charge{
  margin-left: 80%;
 // margin-bottom: -30px;
}

.btn-save-charge{
  margin-left: -40%;
  margin-top: 11px
}

.form-bordered{
  border: 1px solid #ccc;
    padding: 15px 25px;
    margin-bottom: 20px;
    margin-top: 20px
  
}

.assessment-input{
  width: 40%;
  margin-left: -30px;
  margin-top: 8px
}
.assessment-input-select{
  width: 25%;
  margin-left: 10px;
  margin-top: 8px;
  margin-right: -50px

}

.fy-assessment-input-select{
  padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: 8px;
    margin-left: -80px;
}

.add-charge-button{
  margin-right: 20px;
}

.assessment-delete-button{
font-size: 14px;
margin-left: 2px;
    cursor: pointer;
    margin-top: 14px;
}

.warning-modal .modal-content{
  width: 105%;
}

.document-modal-table{
  display: flex;
  justify-content: space-between;
  
  .document-left-portion {
    width: 35%;
  padding: 10px;
}
.document-right-portion {
  width: 65%;
padding-left: 10px;
margin-top: -10px;
}
}

.hoa-document-manage { 
  margin-top: 1rem;
  width: 100%;  
  .hoa-noDocument{
    margin-left: 7.5rem;
    display: flex;
  }  
  .hoa-document{
    margin-left: 13rem;
    display: flex;
  }  
   .hoa-document-heading {
          text-align: right;         
          margin-top: 10px;            
          font-weight: bold;
    }     
    .association-file-btn{
        margin-left: 1rem;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .noDocument {
        margin-top: 10px;
        margin-left: 1rem;
        margin-right: 0rem!important;
    } 
}
.hoa-document-table{
  margin-top: -3rem;
}
.hoa-noDocument-table{
  margin-top: 0rem;
}