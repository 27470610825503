.modal-dialog.municipal-verification-modal {
    min-width: 95%;
  }

  .code-voilation-button{
    padding-left: 15px;
    @media screen and ( min-width: 900px ) and ( max-width: 1300px ) {
      padding-left: 30px !important;
    }
  @media screen and ( min-width: 1300px ) and ( max-width: 1470px ) {
    
    padding-left: 15px!important;
    }
    @media screen and ( min-width: 1400px ) and ( max-width: 1700px ) {
      padding-left: 15px!important;
    }

  }