@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .react-bootstrap-table .table {
    table-layout: auto ;
  }
  
  input {
    width: 100%;
    border: 1px solid lightgray;
    height: 36px;
    border-radius: 6px;
  }
  
  .createBtn {
    float: right;
    margin-right: 10px;
  }
  
  .hide {
    display: none !important;
  }
  
  .loader-img-div {
    position: fixed;
    top: 50%;
    left: 50%;
  }
  
  .loader-img-div > img {
    height: 50px;
  }
  
  .charges-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  
  .center-align {
    text-align: center;
  }
  
  .font-awesome-enaled {
    text-align: center;
    cursor: pointer;
    line-height: normal;
    margin-right: 4%;
  }
  .font-awesome-disabled {
    color: red;
    text-align: right;
    cursor: not-allowed;
    line-height: normal;
    margin-left: 25%;
  }
  .property-hoa-select {
    margin-bottom: 10px;
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* select {
    -webkit-appearance: none;
  } */
  /* .customDropdownIcon {
    float: right;
    margin-right: 8px;
    margin-top: -28px;
    width: 1em;
    color: "black";
  }
   */
.header {
  height: 72px;
  padding: 0 2rem;
  z-index: 20;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15); }

.header::after {
  display: block;
  clear: both;
  content: ""; }

.header .header-logo {
  position: relative;
  background: white;
  background-position: center; }

.header .user-div {
  height: 58px;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  right: 0;
  color: black; }

.header .user-div .users-sub-div p {
  font-weight: bold; }

.header .user-div .userimg img {
  height: 50px;
  cursor: pointer; }

.header .user-div .users-sub-div .logout-btn {
  cursor: pointer; }

.searchBar {
  width: 40%;
  margin-top: 1rem;
  margin-left: 10rem; }
  @media screen and (min-width: 900px) and (max-width: 1300px) {
    .searchBar {
      width: 35%; } }
  @media screen and (min-width: 1300px) and (max-width: 1470px) {
    .searchBar {
      width: 40%; } }
  @media screen and (min-width: 1400px) and (max-width: 1700px) {
    .searchBar {
      width: 40%; } }

.search-btn {
  margin: auto !important; }

.quickStatus Button {
  background: #F32C52;
  border-radius: 0px !important;
  color: white;
  font-weight: bold;
  border-color: #F32C52;
  margin-top: 2rem; }

.quickStatus Button:hover {
  background: #F32C52;
  border-radius: 0px #F32C52 !important;
  color: white;
  font-weight: bold;
  border-color: #F32C52;
  margin-top: 2rem; }

.search_type_selection_div {
  width: 10%;
  margin-left: 5%; }

.login-container {}

.login-container .login-div {
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 15%;
}

.login-container .login-div .login-form {
    width: 40%;
}
input {
    padding: 15px;
    border-radius: 12px !important;
}
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 25px #ffffff inset;
}
.btn {
    border-radius: 12px !important;
}
  .container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 15px;
    position: fixed;
  margin-top: 20%;
  }
  .wrap-login100 {
    width: 410px;
    border-radius: 10px;
    overflow: hidden;
    padding: 55px 55px 37px 55px;
    background: #2356a4;
    background: -webkit-linear-gradient(top, #525352,#c1c1c178);
    margin-top: -60%;   
    color: #fff;
  }
  .login100-form-logo {
    font-size: 60px;
    color:white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 159px;
    height: 162px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto;
  }
  .wrap-input100 {
    width: 100%;
    position: relative;
    /* border-bottom: 2px solid rgba(255,255,255,0.24); */
    margin-bottom: 35px;
  }
  .input100 {
    font-size: 16px;
    color:white;
    font-weight: 600;
    line-height: 1;
    display: block;
    width: 100%;
    background: transparent;
    margin-left: 9%;
    height: 45px;
    margin-top: 10%;
    padding: 0 5px 0 15px;
    border: 1px solid whitesmoke;
    margin-left: 1%;
  }
  ::-webkit-input-placeholder {
    color: white;
  }
  ::-ms-input-placeholder {
    color: white;
  }
  ::placeholder {
    color: white;
  }

  .container-login100-form-btn {
    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 27%;
  }
  .login100-form-btn {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #555555;
    line-height: 1.2;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
    background: #9152f8;
    position: relative;
    z-index: 1;
    margin-top: -11%;
    border-style:none ;
  }
  .login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: white;
  }

  
.property-upload {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.property-upload .page-title {
    font-size: 2rem;
    font-weight: 600;
}

.import-assoc-file {
    display: -webkit-flex;
    display: flex;
}

.import-assoc-file input {
        height: 40px !important;
        padding: 4px;
        border-radius: none;
    }

.import-assoc-file  button {
        border-radius: 10px;
        border: none;
        background: gray;
        color: white;
        margin-left: 10px;
    }

.template {
	color: blue!important;
}
.content {
    margin-top: 11vh;
    margin-left: 18%;
}

.content.searchtable{
    margin-top: 24vh;
}

.content .content-title {
    padding: 10px 0px 0px 0px;
    position: relative;
}

.content .content-title .page-title {
    font-size: 2rem;
    font-weight: 600;
}

.content .content-title .page-heading {
    margin-bottom: 15px;
}

.content .content-title .search-btn {
    margin: auto !important;
}
/* .utility{
    width: 250px;
    display: flex;
    height: 50px;
    padding: 4px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.utilityItem {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 10px;
    border-radius: 50%;
    color: #dee4e7;
    text-shadow: 1px 1px black;
    font-size: 12;
    font-family: "Audiowide", sans-serif;
}
.utilityItem.electricity{
    background-color: red;
}
.utilityItem.water{
    background-color: yellow;
}
.utilityItem.gas{
    background-color: black;
}
.utilityItem.sewage{
    background-color: green;
}
.utilityItem.trash{
    background-color: brown;
} */
.per-page {
    display: -webkit-flex;
    display: flex;
    text-align: right;
    width: 50%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
        -webkit-align-items: baseline;
                align-items: baseline;
            margin-left: 4%;
}

.page-no {
    display: -webkit-flex;
    display: flex;
    text-align: right;
    width: 43%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
        -webkit-align-items: baseline;
                align-items: baseline;
}

.page-wrapper {
	    display: -webkit-flex;
	    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.hidden {
    display: none;
}
.import-modal .import-file {
  display: -webkit-flex;
  display: flex; }
  .import-modal .import-file input {
    height: 40px;
    padding: 4px;
    border-radius: none; }
  .import-modal .import-file button {
    border-radius: 10px;
    border: none;
    background: gray;
    color: white;
    margin-left: 10px; }

.modal-dialog.status-modal {
  min-width: 60%; }
  .modal-dialog.status-modal .clientid-width {
    width: 2%; }
  .modal-dialog.status-modal .status-width {
    width: 2%; }
  .modal-dialog.status-modal .upload-status .status {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.general-info-tabcol {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .general-info-tabcol .listCompButton {
    float: left;
    width: 100%; }
    .general-info-tabcol .listCompButton button {
      float: left; }
  .general-info-tabcol .details {
    padding: 10px;
    width: 100%; }
    .general-info-tabcol .details .message {
      text-align: center;
      margin-top: 24px; }
    .general-info-tabcol .details .heading {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: crimson;
      margin-bottom: 20px; }
    .general-info-tabcol .details .buttons {
      display: -webkit-flex;
      display: flex;
      margin-bottom: 10px; }
      .general-info-tabcol .details .buttons button {
        border-radius: 4px;
        width: 70px;
        height: 35px;
        border: none; }
      .general-info-tabcol .details .buttons .update-btn button {
        background: #0e7bd8;
        color: white; }
      .general-info-tabcol .details .buttons .delete-btn button {
        color: white;
        background: #ea2f2f; }
    .general-info-tabcol .details .edit-btn {
      border-radius: 4px;
      width: 70px;
      height: 35px;
      border: none;
      background-color: gray; }
    .general-info-tabcol .details .info {
      padding: 5px 5px 5px 5px;
      display: -webkit-flex;
      display: flex; }
      .general-info-tabcol .details .info .name {
        font-size: 12px;
        font-weight: 600; }
      .general-info-tabcol .details .info .value {
        font-size: 14px;
        margin-left: 5px; }
    .general-info-tabcol .details .create-btn {
      position: relative;
      float: right;
      left: -5%; }
      .general-info-tabcol .details .create-btn button {
        border-radius: 4px;
        width: 70px;
        height: 35px;
        border: none;
        color: white;
        background: #118709; }
    .general-info-tabcol .details .accesscodes {
      border-bottom: 4px solid powderblue; }
      .general-info-tabcol .details .accesscodes .buttons {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 10px; }
        .general-info-tabcol .details .accesscodes .buttons .create-btn {
          margin-right: 5px;
          position: absolute;
          top: 20px;
          right: 30px; }
    .general-info-tabcol .details .lease-info {
      border-bottom: 4px solid powderblue; }
      .general-info-tabcol .details .lease-info .buttons {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 10px; }
        .general-info-tabcol .details .lease-info .buttons .create-btn {
          margin-right: 5px;
          position: absolute;
          top: 20px;
          right: 30px; }
    .general-info-tabcol .details .property-hoa-list {
      border-bottom: 4px solid powderblue; }

.listCompButton {
  position: relative;
  float: right;
  margin-top: -7%;
  margin-right: 2%;
  width: 65%; }
  .listCompButton button {
    float: right; }

.addMarginBottom {
  margin-bottom: 1%; }

.paginationPageSelect {
  /*
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;*/
  width: 24% !important;
  margin-right: 5%;
  margin-left: 4%; }

.general .headings {
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  -webkit-align-items: baseline;
          align-items: baseline; }
  .general .headings h5 {
    padding-right: 10px; }

.general .table-empty {
  padding: 10px; }

.header-cell-alignment {
  text-align: center !important; }

.page {
    display: -webkit-flex;
    display: flex;
    margin-top: 72px;
    height: -webkit-fill-available;
}

.page .sidebar {
    width: 15%;
    background-color: lightgray;
}

.page .page-content {
    width: 85%;
}

/* .history {
     display: none;
} */
.modal-dialog.vendor-modal {
  min-width: 85%; }

.add-model-right-portion {
  width: 45%;
  padding: 10px; }
  .add-model-right-portion .add-poc {
    color: white;
    display: -webkit-flex;
    display: flex;
    border: 1px solid white;
    background: #53bf53;
    border-radius: 20px;
    width: 25%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer; }
    .add-model-right-portion .add-poc .text {
      padding: 6px; }

.poc-style {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 20px; }

.modal-dialog.codeViolation-modal {
  min-width: 95%; }

.modal-content {
  min-width: 50%; }

.code-violation-radio-button {
  display: -webkit-inline-flex;
  display: inline-flex;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px); }
  .code-violation-radio-button .custom-control {
    margin-right: 0px;
    margin-left: 5px; }

.code-violation-text {
  width: 100%;
  text-align: right;
  font-weight: bold; }

.case-number {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px); }

.case-number:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer; }

.code-violation-date {
  width: 115px; }

.modal-dialog.violationFileUpload-modal {
  min-width: 35%; }

.modal-dialog.hearing-modal {
  min-width: 50%; }

.modal-dialog.hearing-listing-modal {
  min-width: 80%; }

.modal-dialog.attendees-modal {
  min-width: 50%; }

.code-violation-hearing-radio-button {
  padding-top: calc(0.375rem + 1px);
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-left: 15px; }
  .code-violation-hearing-radio-button .custom-control {
    margin-right: 0px;
    margin-left: 5px; }

.hearing-buttons {
  margin-top: 0px;
  margin-bottom: 0px !important; }

.attendees_table tr, .attendees_table th, .attendees_table td {
  border: 1px solid black;
  cursor: pointer; }

.code {
  margin-bottom: 20px; }

.aco {
  border: 1px solid #aaa;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 20px; }

.questions .question {
  margin: 10px; }

.questions .radio-buttons {
  display: -webkit-flex;
  display: flex; }

.acess-code-questions {
  display: -webkit-flex;
  display: flex; }
  .acess-code-questions .access-question {
    margin: 10px; }
  .acess-code-questions .acess-code-radio-buttons {
    margin: 10px;
    display: -webkit-flex;
    display: flex; }

.access-codes-container {
  width: 100%;
  padding-right: 55px; }
  .access-codes-container .edit-link {
    position: absolute;
    left: 50%; }
  .access-codes-container .accesss-codes {
    display: -webkit-flex;
    display: flex; }
    .access-codes-container .accesss-codes .heading {
      font-weight: bold;
      min-width: 200px !important; }
  .access-codes-container .accesss-codes-values {
    margin-top: -20px;
    padding: 10px; }

.custom-control {
  margin-right: 10px;
  margin-left: 10px; }

.access-code-modal.modal-dialog {
  max-width: 85%; }
  .access-code-modal.modal-dialog .modal-content {
    width: 100%;
    padding-left: 0%; }
  .access-code-modal.modal-dialog .control-details {
    padding: 10px; }
  .access-code-modal.modal-dialog .code-notes {
    padding: 0px 10px 0px 10px;
    margin-bottom: 0rem; }
  .access-code-modal.modal-dialog .yes-no-div {
    display: block;
    margin-bottom: 10px; }
    .access-code-modal.modal-dialog .yes-no-div .yes-no {
      display: -webkit-flex;
      display: flex; }
  .access-code-modal.modal-dialog .access-form {
    position: relative;
    bottom: 60px;
    width: 45%;
    left: 30%;
    margin-top: 1.5rem; }
    .access-code-modal.modal-dialog .access-form .access-file-btn {
      margin-top: 0px !important; }
  .access-code-modal.modal-dialog .create-Btn {
    position: absolute;
    bottom: 16px;
    left: 125px; }
  .access-code-modal.modal-dialog .form-heading {
    font-size: 24px;
    font-weight: bold; }

thead tr th {
  width: 10%; }

.payment-modal.modal-dialog {
  max-width: 98%; }
  .payment-modal.modal-dialog .modal-content .modal-body {
    padding-left: 0%;
    margin-bottom: -2%; }
    .payment-modal.modal-dialog .modal-content .modal-body .hide-button {
      display: none; }

.payment-section {
  margin-top: -20px;
  margin-left: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: -30px;
  font-size: 13px; }
  .payment-section .payment-form .track {
    margin-left: 0px;
    display: -webkit-flex;
    display: flex;
    width: 245px; }
    .payment-section .payment-form .track label {
      text-align: end;
      width: 40%;
      margin-right: 4px; }
    .payment-section .payment-form .track > div {
      padding-right: 5px;
      width: 58%; }
  @media (max-width: 1250px) {
    .payment-section .payment-form .track {
      width: 195px; }
    .payment-section .payment-form label {
      text-align: end;
      width: 40%;
      margin-right: 2px; }
    .payment-section .payment-form > div {
      width: 57%; } }
  .payment-section .payment-form .section {
    margin-left: 0px; }
    .payment-section .payment-form .section .fields {
      display: -webkit-flex;
      display: flex;
      width: 245px;
      -webkit-align-items: center;
              align-items: center; }
      .payment-section .payment-form .section .fields label {
        text-align: end;
        margin-right: 4px;
        width: 40%; }
      .payment-section .payment-form .section .fields > div {
        padding-right: 5px;
        width: 60%; }
        .payment-section .payment-form .section .fields > div select {
          font-size: 13px; }
        .payment-section .payment-form .section .fields > div input {
          font-size: 15px;
          text-align: center;
          color: black; }
    .payment-section .payment-form .section .add-margin {
      margin-left: 245px; }
  @media (max-width: 1250px) {
    .payment-section .payment-form {
      font-size: 12px; }
      .payment-section .payment-form .section {
        margin-left: 0px; }
        .payment-section .payment-form .section .fields {
          display: -webkit-flex;
          display: flex;
          width: 195px; }
          .payment-section .payment-form .section .fields label {
            text-align: end;
            margin-right: 2px;
            width: 40%; }
          .payment-section .payment-form .section .fields > div {
            padding-right: 3px;
            width: 60%; }
        .payment-section .payment-form .section .add-margin {
          margin-left: 195px; } }
  .payment-section .payment-form .memo-edit-icon {
    position: absolute;
    top: 10%;
    left: -10%;
    cursor: pointer; }
  .payment-section .payment-form .heading {
    font-size: 16px;
    padding-top: 28px; }
  .payment-section .payment-form .bold-text-div {
    font-size: 12px;
    font-weight: bold; }
  .payment-section .payment-documents {
    width: 50%;
    padding: 10px; }
    .payment-section .payment-documents .heading {
      font-weight: bold;
      padding: 10px;
      margin-top: 10px;
      margin-left: -1%; }

.other-payment-section {
  padding: 15px; }

.charges-section .charges-payment-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .charges-section .charges-payment-data label {
    text-align: right; }
  .charges-section .charges-payment-data .charges-payment-data-left {
    width: 40%;
    padding: 10px; }
  .charges-section .charges-payment-data .charges-payment-data-right {
    width: 50%;
    padding: 10px; }

.charges-section .payment-rejected {
  padding: 0px 10px 0px 10px;
  margin-top: -1%; }

.charges-section .payment-comments .status-comment {
  margin-right: 5px;
  margin-left: 5px; }

.charges-section .approval-comment {
  margin-top: 2%; }

.charges-section .charge-table {
  padding-left: 10px; }
  .charges-section .charge-table .charges-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 10px;
    margin-top: -40px; }
    .charges-section .charge-table .charges-header > .charges-hoas {
      width: 40%;
      display: -webkit-flex;
      display: flex; }
    .charges-section .charge-table .charges-header > .charges-hoas Label {
      padding: 5px; }
    .charges-section .charge-table .charges-header > .charges-date {
      width: 30%;
      display: -webkit-flex;
      display: flex;
      margin-left: 10px; }
    .charges-section .charge-table .charges-header > .charges-date Label {
      padding: 5px; }
    .charges-section .charge-table .charges-header > .add-charge button {
      margin: 0;
      width: 100%; }
    .charges-section .charge-table .charges-header > .add-charge {
      margin-left: 10px;
      margin-top: -45px; }

.charges-section .approval-btn {
  bottom: 2rem;
  position: absolute;
  left: 47%; }

.tracking {
  width: 9%;
  padding-left: 1.5%; }

.documentName-label {
  width: 13%;
  padding-left: 1.5%; }

.payment-approved-input {
  width: 125px; }
  .payment-approved-input input {
    text-align: center; }

.tracking-inputField {
  width: 28%;
  padding-left: 1.5%; }

.documentNotes-label {
  width: 11%;
  padding-left: 10px; }

.documentNotes-inputField {
  width: 24%;
  padding-left: 1.5%; }

.documentName-inputField {
  padding-left: 0%;
  width: 18%; }

.payment-information-input {
  width: 10%;
  padding-left: 0.5%; }

.payment-information-label {
  width: 180px;
  padding-left: 1.5%; }

.payment-dueDate-label {
  padding-left: 0.5%;
  width: 13%; }

.invoice-number-label {
  width: 5%;
  padding-left: 0.5%; }

.invoice-received-label {
  width: 180px;
  padding-left: 0.5%; }

.invoice-number-input {
  width: 23%;
  padding-left: 0.5%; }

.heading {
  margin-top: -20px;
  font-weight: bold;
  padding: 10px; }

.payment-info {
  margin-right: 5px;
  margin-left: 10px;
  display: -webkit-flex;
  display: flex; }
  .payment-info .question {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 20%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .payment-info .question .radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .payment-info .question .radio .custom-control {
        margin-right: 0px; }
    .payment-info .question .text {
      text-align: end; }
  .payment-info .payee {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 42%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .payment-info .payee label {
      margin-right: 10px; }
    .payment-info .payee .select-box {
      width: 400px; }
  .payment-info .other-payee {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 42%;
    margin-left: 10px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .payment-info .other-payee label {
      margin-right: 10px;
      width: 160px;
      text-align: end; }
    .payment-info .other-payee .select-box {
      width: 400px; }
  .payment-info .memo {
    width: 35%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    display: -webkit-inline-flex;
    display: inline-flex; }
    .payment-info .memo-head {
      font-weight: 600;
      padding-right: 10px;
      width: 180px;
      text-align: end; }
    .payment-info .memo-details {
      width: 20em;
      display: -webkit-flex;
      display: flex; }
  .payment-info .reciepent-addr {
    width: 35%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    display: inline-block; }
    .payment-info .reciepent-addr .reciepent-addr-data {
      display: -webkit-flex;
      display: flex; }
    .payment-info .reciepent-addr .care-of {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: baseline;
              align-items: baseline; }
    .payment-info .reciepent-addr-head {
      font-weight: 600;
      padding-right: 10px;
      width: 270px;
      text-align: end; }
    .payment-info .reciepent-addr-details {
      width: 20em;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: baseline;
              align-items: baseline; }

.settlement-select {
  height: 35; }

.paymentTable {
  margin-top: -2%; }

.documentClass {
  padding-left: 2%;
  margin-bottom: 1%; }

.documentUploadLabel {
  width: 15%;
  padding-left: 21px; }

.documentUploadInput {
  width: 18%; }

.nodocumentUpload {
  display: -webkit-flex;
  display: flex;
  width: 20%; }
  .nodocumentUpload .noDocument {
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
    margin-left: 10px; }

.documentUpload {
  width: 8%; }

.document-table {
  width: 90%;
  margin-right: -5rem; }

.other-acc {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-right: 68px; }
  .other-acc .acc {
    display: -webkit-flex;
    display: flex; }
    .other-acc .acc .text {
      width: 19em; }
  .other-acc label {
    margin-right: 10px; }

.react-datepicker-wrapper .react-datepicker__input-container input {
  text-align: center; }

.notes-width {
  width: 80% !important;
  word-break: break-all; }

.header-cell-alignment {
  text-align: left !important; }

.documentTab {
  width: 95%; }

.hoa-documentTab {
  width: 100%;
  margin-top: 1.5rem; }

.modal-dialog.lienAction-modal {
  min-width: 95%; }

.radio-button {
  display: -webkit-flex;
  display: flex; }
  .radio-button .custom-control {
    margin-right: 0px;
    margin-left: 5px; }

.title-lien-radio-col {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px); }

.edit-address .modal-content {
  width: 150%; }

.tab-content {
  padding: 20px 0px 0px 0px; }

.nav-tabs {
  border-bottom: none !important; }

.tab li.nav-item {
  background-color: darkgrey;
  min-width: 18.6%;
  text-align: center; }
  .tab li.nav-item.active {
    margin-top: -0.5%;
    background-color: lightblue;
    border: none; }
  .tab li.nav-item .nav-link {
    color: black;
    display: block;
    padding: 0.5rem 0.5rem; }

.tab li.nav-item .nav-link.active {
  background-color: lightblue;
  border-color: lightblue; }

.general-info-tab {
  border-bottom: 2px solid #dee2e6 !important; }
  .general-info-tab .general-info-tabnavitem {
    margin-bottom: -2px !important;
    cursor: pointer; }
    .general-info-tab .general-info-tabnavitem .general-info-tabnavlink.active {
      color: #495057;
      background-color: #fff;
      border-bottom: 4px solid lightblue;
      border-top: none;
      border-right: none;
      border-left: none; }
    .general-info-tab .general-info-tabnavitem .nav-link {
      color: black;
      display: block;
      padding: 0.5rem 0.5rem; }

.modalTabs {
  margin-left: 2%; }
  .modalTabs .nav-item {
    width: 45%;
    text-align: center; }
    .modalTabs .nav-item.active {
      margin-top: -0.5%;
      background-color: lightblue;
      border: none; }

.general-info-tabpage-content {
  padding: 20px 10px 0px 0px; }

.create-img {
    height: 20px ;
}
.filter-img{
    height: 20px ;
}
.creating {
    float: left;
    margin-top: 0%;

}
.filtering {
    display: none;
    float: right;
    margin-top: 0%;
    margin-right:56%;
}
.create {
    /* margin-top: -4%; */
    margin-top: 0%;
}
.btn {
    margin-top: 10px;
    margin-bottom: 30px;
}
.action-description{
    width: 50% !important;
}
.reduce-width{
    width: 10% !important;
}
.notes-width{
    width: 80% !important;
    word-break: break-all;
}

.lien-modal .modal-content .modal-body .hide-button {
  display: none; }

.form-modal.modal-dialog {
  max-width: 95%; }

.hoaForm.modal-dialog {
  max-width: 95%; }

.add-management-company {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .add-management-company .add-model-left-portion {
    width: 55%;
    padding: 10px; }
  .add-management-company .add-model-right-portion {
    width: 45%;
    padding: 10px; }
    .add-management-company .add-model-right-portion .note {
      font-size: 10px; }
    .add-management-company .add-model-right-portion .add-poc {
      color: white;
      display: -webkit-flex;
      display: flex;
      border: 1px solid white;
      background: #53bf53;
      border-radius: 20px;
      width: 25%;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      cursor: pointer; }
      .add-management-company .add-model-right-portion .add-poc .text {
        padding: 8px; }
    .add-management-company .add-model-right-portion .code {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-bottom: 0px;
      margin-top: 0px;
      padding-right: 10px;
      width: 90%; }
      .add-management-company .add-model-right-portion .code .question {
        display: -webkit-inline-flex;
        display: inline-flex;
        width: 50%; }
        .add-management-company .add-model-right-portion .code .question .rightside-content {
          padding-left: 25px; }
        .add-management-company .add-model-right-portion .code .question .radio {
          display: -webkit-inline-flex;
          display: inline-flex; }
          .add-management-company .add-model-right-portion .code .question .radio .custom-control {
            margin-right: 0px; }
        .add-management-company .add-model-right-portion .code .question .text {
          width: 100%;
          text-align: right; }
    .add-management-company .add-model-right-portion .question {
      display: -webkit-inline-flex;
      display: inline-flex;
      width: 88%; }
      .add-management-company .add-model-right-portion .question .rightside-content {
        padding-left: 25px; }
      .add-management-company .add-model-right-portion .question .radio {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .add-management-company .add-model-right-portion .question .radio .custom-control {
          margin-right: 0px; }
      .add-management-company .add-model-right-portion .question .text {
        width: 100%;
        text-align: right; }
    .add-management-company .add-model-right-portion .noDocument {
      margin-top: 10px;
      text-align: right; }
    .add-management-company .add-model-right-portion .file-btn {
      margin-left: 10rem;
      margin-top: 0px !important;
      margin-bottom: 0px !important; }

.management-company-poc {
  font-weight: bold;
  text-align: end;
  font-size: 15px !important;
  margin-top: -10px; }

.poc-style {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 20px; }

.add_assessment {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .add_assessment .fy_assessment {
    border: 1px solid lightgrey;
    padding: 10px;
    width: 48%;
    margin: 8px; }

.add_fy_Assesment {
  color: white;
  display: -webkit-flex;
  display: flex;
  border: 1px solid white;
  background: #53bf53;
  border-radius: 20px;
  width: 20%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  height: 0%; }
  .add_fy_Assesment .text {
    padding: 8px; }

.address-body {
  border: 1px solid lightgray;
  padding: 15px 10px 0px 10px;
  margin: 10px 0px 0px; }

.address-name {
  border-radius: 0px 15px 15px 0px !important; }

.rdg-editor-container {
  z-index: 10051 !important; }

.add_New_Assessment {
  color: white;
  display: -webkit-flex;
  display: flex;
  border: 1px solid white;
  background: #53bf53;
  border-radius: 20px;
  width: 13%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 8px;
  cursor: pointer; }

.add_New_Assessment.disable {
  pointer-events: none;
  opacity: 0.5; }

.assessment_type {
  font-weight: bold;
  margin-top: 1%;
  font-size: 17px;
  color: #ff8f00c4; }

.add_line_item {
  color: white;
  display: -webkit-flex;
  display: flex;
  border: 1px solid white;
  background: #6c757d;
  border-radius: 20px;
  width: 200%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: -2%;
  margin-left: 85%;
  padding: 8px; }

.table_allignment {
  margin-top: 1%; }

.new_assessment {
  margin-top: 1%;
  min-width: 30%;
  margin-right: 10px; }

.add_address {
  display: -webkit-flex;
  display: flex; }

.add-charge {
  margin-left: 80%; }

.btn-save-charge {
  margin-left: -40%;
  margin-top: 11px; }

.form-bordered {
  border: 1px solid #ccc;
  padding: 15px 25px;
  margin-bottom: 20px;
  margin-top: 20px; }

.assessment-input {
  width: 40%;
  margin-left: -30px;
  margin-top: 8px; }

.assessment-input-select {
  width: 25%;
  margin-left: 10px;
  margin-top: 8px;
  margin-right: -50px; }

.fy-assessment-input-select {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-top: 8px;
  margin-left: -80px; }

.add-charge-button {
  margin-right: 20px; }

.assessment-delete-button {
  font-size: 14px;
  margin-left: 2px;
  cursor: pointer;
  margin-top: 14px; }

.warning-modal .modal-content {
  width: 105%; }

.document-modal-table {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .document-modal-table .document-left-portion {
    width: 35%;
    padding: 10px; }
  .document-modal-table .document-right-portion {
    width: 65%;
    padding-left: 10px;
    margin-top: -10px; }

.hoa-document-manage {
  margin-top: 1rem;
  width: 100%; }
  .hoa-document-manage .hoa-noDocument {
    margin-left: 7.5rem;
    display: -webkit-flex;
    display: flex; }
  .hoa-document-manage .hoa-document {
    margin-left: 13rem;
    display: -webkit-flex;
    display: flex; }
  .hoa-document-manage .hoa-document-heading {
    text-align: right;
    margin-top: 10px;
    font-weight: bold; }
  .hoa-document-manage .association-file-btn {
    margin-left: 1rem;
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .hoa-document-manage .noDocument {
    margin-top: 10px;
    margin-left: 1rem;
    margin-right: 0rem !important; }

.hoa-document-table {
  margin-top: -3rem; }

.hoa-noDocument-table {
  margin-top: 0rem; }

input[type="radio"].radio-button {
  width: 5px;
  height: 5px; }

.right-align {
  text-align: right !important; }

div.container-fluid {
  font-size: 12px; }
  div.container-fluid .horizonal-line-leasing {
    width: 101%;
    position: relative;
    right: 16px; }
  div.container-fluid .submit-button-align {
    text-align: center; }

.no-Document {
  position: relative;
  top: 10px; }

.display-in-same-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .display-in-same-row .text-yes {
    position: relative;
    right: 15px;
    top: 3px; }
  .display-in-same-row .text-no {
    position: relative;
    right: 30px;
    top: 3px; }
  .display-in-same-row .gap-between-yes-no {
    position: relative;
    right: 15px; }
  .display-in-same-row .no-wrap {
    white-space: nowrap; }

.left-align {
  text-align: left !important; }

.amenities-file-upload {
  display: -webkit-flex;
  display: flex; }
  .amenities-file-upload .noDocument {
    margin-top: 10px;
    margin-left: 10px; }

.upload-button-align {
  position: relative;
  bottom: 12px; }

.text_align {
  white-space: nowrap;
  text-align: right !important; }

.township-address-leasing {
  position: relative;
  top: 5px; }
  .township-address-leasing .textbox-width {
    width: 170px; }

.rental-fee-margin-leasing {
  margin-left: 45px; }
  .rental-fee-margin-leasing .fee-sub-div {
    position: relative;
    top: 9px;
    left: 70px; }
    .rental-fee-margin-leasing .fee-sub-div .textbox-width {
      width: 100px; }

.leasing-date-styling {
  position: relative;
  right: 2px; }
  .leasing-date-styling .sub-text-span {
    position: relative;
    top: 17px;
    margin-left: 20px; }
  .leasing-date-styling .date-span {
    position: absolute;
    width: 100px;
    top: 10px; }

.move-in-fee-styling {
  position: relative;
  left: 58px; }
  .move-in-fee-styling .move-in-sub-ques {
    position: relative;
    top: 5px; }
  .move-in-fee-styling .move-in-fee-textbox {
    position: relative;
    top: 8px;
    width: 100px; }

.file-upload-button-div {
  display: -webkit-flex;
  display: flex;
  position: relative !important;
  top: 5px;
  width: 35%; }
  .file-upload-button-div .file-upload-button {
    margin-top: 0px;
    margin-bottom: 5px;
    width: 55% !important; }

.max-length-board-styling {
  position: relative;
  right: 40px;
  bottom: 5px; }
  .max-length-board-styling .max-length-sub-ques {
    position: relative;
    top: 12px; }
  .max-length-board-styling .max-length-days {
    position: relative;
    top: 12px; }
  .max-length-board-styling .textbox-width {
    position: relative;
    width: 60px;
    top: 12px; }

.min-month-unit-own-leasing-width {
  width: 60px; }

.time-unit-lease-year-div {
  margin-left: 45px; }
  .time-unit-lease-year-div .textbox-width {
    width: 60px; }

.lease-document-table {
  width: 100%;
  position: relative;
  text-align: justify;
  margin-right: 2rem;
  overflow-x: hidden; }

div.react-datepicker-popper > div {
  position: relative;
  right: 49px; }

::-webkit-input-placeholder {
  color: grey; }

::-ms-input-placeholder {
  color: grey; }

::placeholder {
  color: grey; }

.lease-radio-question {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 0px;
  padding-right: 10px; }
  .lease-radio-question .lease-question {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 46%; }
    .lease-radio-question .lease-question .rightside-content {
      padding-left: 25px; }
    .lease-radio-question .lease-question .lease-radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .lease-radio-question .lease-question .lease-radio .custom-control {
        margin-right: 0px;
        margin-top: -2px; }
    .lease-radio-question .lease-question .lease-text {
      width: 100%;
      text-align: right; }
  .lease-radio-question .input-fields {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 48%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0px 20px 5px 10px;
    margin-right: 4rem; }
    .lease-radio-question .input-fields label {
      text-align: right;
      padding-right: 10px;
      -webkit-align-self: center;
              align-self: center; }
    .lease-radio-question .input-fields div {
      -webkit-align-self: center;
              align-self: center; }
    .lease-radio-question .input-fields input {
      width: 30%; }
    .lease-radio-question .input-fields .date-picker {
      width: 100%; }
    .lease-radio-question .input-fields .react-datepicker-wrapper {
      width: 20%; }
      .lease-radio-question .input-fields .react-datepicker-wrapper .react-datepicker__input-container input {
        text-align: center; }

.modal-dialog.association-modal {
  min-width: 90%; }

.pos {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  bottom: 16vh;
  left: 40%; }
  .pos label {
    padding-right: 10px;
    -webkit-align-self: center;
            align-self: center; }
  .pos div {
    padding-right: 10px; }

.status {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%; }
  .status div {
    padding-right: 10px; }

.poc {
  width: 100%;
  float: right; }
  .poc .ph {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .poc .ip-fields {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 7px 25px 7px 10px; }
    .poc .ip-fields input {
      width: 440px; }
    .poc .ip-fields label {
      padding-right: 10px;
      -webkit-align-self: center;
              align-self: center;
      text-align: right; }
    .poc .ip-fields div {
      -webkit-align-self: center;
              align-self: center; }
    .poc .ip-fields #ext2 {
      width: 100px; }
    .poc .ip-fields #phone2 {
      width: 264px; }
    .poc .ip-fields #ext3 {
      width: 100px; }
    .poc .ip-fields #phone3 {
      width: 264px; }

.code-radio {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-right: 10px; }
  .code-radio .question {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%; }
    .code-radio .question .rightside-content {
      padding-left: 25px; }
    .code-radio .question .radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .code-radio .question .radio .custom-control {
        margin-right: 0px; }
    .code-radio .question .text {
      width: 100%;
      text-align: right; }
  .code-radio .ip-fields {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0px 20px 5px 10px; }
    .code-radio .ip-fields label {
      text-align: right;
      padding-right: 10px;
      -webkit-align-self: center;
              align-self: center; }
    .code-radio .ip-fields div {
      -webkit-align-self: center;
              align-self: center; }
    .code-radio .ip-fields #point_of_contact_1 {
      width: 440px; }
    .code-radio .ip-fields #email1 {
      width: 440px; }

.next-section {
  display: -webkit-flex;
  display: flex; }
  .next-section .ques {
    width: 50%; }
    .next-section .ques .question {
      display: -webkit-flex;
      display: flex; }
      .next-section .ques .question .text {
        width: 100%;
        text-align: right; }
      .next-section .ques .question .radio {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .next-section .ques .question .radio .custom-control {
          margin-right: 0px; }
    .next-section .ques .sel {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: center;
              align-items: center;
      padding: 5px 0px 5px 0px; }
      .next-section .ques .sel label {
        padding-right: 10px; }
    .next-section .ques .ip-fields {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 5px 0px 5px 0px; }
      .next-section .ques .ip-fields label {
        padding-right: 10px; }
      .next-section .ques .ip-fields div {
        width: 450px; }
        @media screen and (max-width: 780px) {
          .next-section .ques .ip-fields div {
            width: 390px; } }
    .next-section .ques .tel {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 5px 0px 5px 0px; }
      .next-section .ques .tel .Phone {
        display: -webkit-flex;
        display: flex;
        width: 315px; }
        .next-section .ques .tel .Phone div {
          padding-left: 10px; }
        @media screen and (max-width: 780px) {
          .next-section .ques .tel .Phone {
            width: 260px; } }
      .next-section .ques .tel .Ext {
        width: 190px;
        display: -webkit-flex;
        display: flex; }
        .next-section .ques .tel .Ext label {
          padding-left: 10px; }
        .next-section .ques .tel .Ext div {
          padding-left: 10px; }
  .next-section .hoa {
    width: 50%; }
    .next-section .hoa .question {
      display: -webkit-flex;
      display: flex;
      padding: 15px 0px 5px 0px;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      .next-section .hoa .question .radio {
        display: -webkit-flex;
        display: flex; }
        .next-section .hoa .question .radio .custom-control {
          margin-right: 0px; }
    .next-section .hoa .sel {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: center;
              align-items: center;
      padding: 5px 0px 5px 0px; }
      .next-section .hoa .sel label {
        padding-right: 10px; }
    .next-section .hoa .ip-fields {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 5px 0px 5px 0px; }
      .next-section .hoa .ip-fields label {
        padding-right: 10px; }
      .next-section .hoa .ip-fields div {
        width: 450px; }
    .next-section .hoa .tel {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 5px 0px 5px 0px; }
      .next-section .hoa .tel .Phone {
        display: -webkit-flex;
        display: flex;
        width: 315px; }
        .next-section .hoa .tel .Phone div {
          padding-left: 10px; }
      .next-section .hoa .tel .Ext {
        width: 190px;
        display: -webkit-flex;
        display: flex; }
        .next-section .hoa .tel .Ext label {
          padding-left: 10px; }
        .next-section .hoa .tel .Ext div {
          padding-left: 10px; }

.nextQuestion {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px; }
  .nextQuestion .question {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%; }
    .nextQuestion .question .rightside-content {
      padding-left: 25px; }
    .nextQuestion .question .radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .nextQuestion .question .radio .custom-control {
        margin-right: 0px; }
    .nextQuestion .question .text {
      width: 100%;
      text-align: right; }
  .nextQuestion .sel {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    padding: 5px 0px 5px 0px; }
    .nextQuestion .sel label {
      padding-right: 10px; }
  .nextQuestion .ip-fields {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 5px 0px 5px 0px; }
    .nextQuestion .ip-fields label {
      padding-right: 10px; }
    .nextQuestion .ip-fields div {
      width: 450px; }

.file-upload {
  margin-left: 11rem;
  margin-bottom: 2rem;
  display: -webkit-flex;
  display: flex; }
  .file-upload .noDocument {
    margin-top: 20px;
    margin-left: 10px; }

.association-document-modal {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: -2rem;
  margin-left: 14rem; }

.modal-dialog.deedVerification-modal {
  min-width: 95%; }

.radio-button {
  display: -webkit-inline-flex;
  display: inline-flex; }
  .radio-button .custom-control {
    margin-right: 0px;
    margin-left: 5px; }

.deed-radio-col {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px); }

.deed-radio {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 9px;
  margin-top: 0px;
  padding-right: 10px;
  width: 90%; }
  .deed-radio .deed-question {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%; }
    .deed-radio .deed-question .rightside-content {
      padding-left: 25px; }
    .deed-radio .deed-question .radio-button {
      display: -webkit-inline-flex;
      display: inline-flex;
      margin-left: 15px; }
      .deed-radio .deed-question .radio-button .custom-control {
        margin-right: 0px;
        margin-left: 5px; }
    .deed-radio .deed-question .deed-text {
      width: 100%;
      text-align: right;
      font-weight: bold; }
  .deed-radio .deed-ip-fields {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0px 0px 5px 10px; }
    .deed-radio .deed-ip-fields label {
      text-align: right;
      padding-right: 10px;
      -webkit-align-self: center;
              align-self: center; }
    .deed-radio .deed-ip-fields textarea {
      width: 50%; }
    .deed-radio .deed-ip-fields select {
      width: 45%; }
    .deed-radio .deed-ip-fields .customDropdownicon-deedverification {
      color: black;
      position: absolute;
      padding: 9px 16px 0 0; }

.deed-question {
  display: -webkit-inline-flex;
  display: inline-flex;
  width: 100%; }
  .deed-question .rightside-content {
    padding-left: 25px; }
  .deed-question .radio-button {
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-left: 15px; }
    .deed-question .radio-button .custom-control {
      margin-right: 0px;
      margin-left: 5px; }
  .deed-question .deed-text {
    width: 100%;
    text-align: right;
    font-weight: bold; }

Label {
  text-align: right;
  font-weight: bold; }

.poa_date {
  width: 45%; }

.upload_deed_document {
  margin-top: 0px !important;
  margin-bottom: 15px; }

.modal-dialog.title-modal {
  min-width: 95%; }

.pos {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  bottom: 16vh;
  left: 40%; }
  .pos label {
    padding-right: 10px;
    -webkit-align-self: center;
            align-self: center; }
  .pos div {
    padding-right: 10px; }

.status {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%; }
  .status div {
    padding-right: 10px; }

.poc {
  width: 100%;
  float: right; }
  .poc .ph {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .poc .ip-fields {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 7px 25px 7px 10px; }
    .poc .ip-fields input {
      width: 440px; }
    .poc .ip-fields label {
      padding-right: 10px;
      -webkit-align-self: center;
              align-self: center;
      text-align: right; }
    .poc .ip-fields div {
      -webkit-align-self: center;
              align-self: center; }
    .poc .ip-fields #ext2 {
      width: 100px; }
    .poc .ip-fields #phone2 {
      width: 264px; }
    .poc .ip-fields #ext3 {
      width: 100px; }
    .poc .ip-fields #phone3 {
      width: 264px; }

.titleReview-code-radio {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 9px;
  margin-top: 0px;
  padding-right: 10px;
  width: 90%; }
  .titleReview-code-radio .question {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%; }
    .titleReview-code-radio .question .rightside-content {
      padding-left: 25px; }
    .titleReview-code-radio .question .radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .titleReview-code-radio .question .radio .custom-control {
        margin-right: 0px;
        margin-left: 5px; }
    .titleReview-code-radio .question .text {
      width: 100%;
      text-align: right; }
  .titleReview-code-radio .ip-fields {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0px 0px 5px 10px; }
    .titleReview-code-radio .ip-fields label {
      text-align: right;
      padding-right: 10px;
      -webkit-align-self: center;
              align-self: center; }
    .titleReview-code-radio .ip-fields textarea {
      width: 50%; }
    .titleReview-code-radio .ip-fields div {
      -webkit-align-self: center;
              align-self: center;
      width: 18%; }
    .titleReview-code-radio .ip-fields #point_of_contact_1 {
      width: 440px; }
    .titleReview-code-radio .ip-fields #email1 {
      width: 440px; }

.titleReview-next-section {
  display: -webkit-flex;
  display: flex;
  width: 90 !important; }
  .titleReview-next-section .ques {
    width: 90%;
    padding-right: 15px; }
    .titleReview-next-section .ques .question {
      display: -webkit-flex;
      display: flex; }
      .titleReview-next-section .ques .question .text {
        width: 100%;
        text-align: right; }
      .titleReview-next-section .ques .question .radio {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .titleReview-next-section .ques .question .radio .custom-control {
          margin-right: 0px;
          margin-left: 5px; }
    .titleReview-next-section .ques .sel {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: center;
              align-items: center;
      padding: 5px 0px 5px 0px; }
      .titleReview-next-section .ques .sel label {
        padding-right: 10px; }
    .titleReview-next-section .ques .ip-fields {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 5px 0px 5px 0px; }
      .titleReview-next-section .ques .ip-fields label {
        padding-right: 10px; }
      .titleReview-next-section .ques .ip-fields div {
        width: 450px; }
    .titleReview-next-section .ques .tel {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 5px 0px 5px 0px; }
      .titleReview-next-section .ques .tel .Phone {
        display: -webkit-flex;
        display: flex;
        width: 315px; }
        .titleReview-next-section .ques .tel .Phone div {
          padding-left: 10px; }
      .titleReview-next-section .ques .tel .Ext {
        width: 190px;
        display: -webkit-flex;
        display: flex; }
        .titleReview-next-section .ques .tel .Ext label {
          padding-left: 10px; }
        .titleReview-next-section .ques .tel .Ext div {
          padding-left: 10px; }
  .titleReview-next-section .hoa {
    width: 50%; }
    .titleReview-next-section .hoa .question {
      display: -webkit-flex;
      display: flex;
      padding: 15px 0px 5px 0px;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      .titleReview-next-section .hoa .question .radio {
        display: -webkit-flex;
        display: flex; }
        .titleReview-next-section .hoa .question .radio .custom-control {
          margin-right: 0px; }
    .titleReview-next-section .hoa .sel {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: center;
              align-items: center;
      padding: 5px 0px 5px 0px; }
      .titleReview-next-section .hoa .sel label {
        padding-right: 10px; }
    .titleReview-next-section .hoa .ip-fields {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 5px 0px 5px 0px; }
      .titleReview-next-section .hoa .ip-fields label {
        padding-right: 10px; }
      .titleReview-next-section .hoa .ip-fields div {
        width: 450px; }
    .titleReview-next-section .hoa .tel {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 5px 0px 5px 0px; }
      .titleReview-next-section .hoa .tel .Phone {
        display: -webkit-flex;
        display: flex;
        width: 315px; }
        .titleReview-next-section .hoa .tel .Phone div {
          padding-left: 10px; }
      .titleReview-next-section .hoa .tel .Ext {
        width: 190px;
        display: -webkit-flex;
        display: flex; }
        .titleReview-next-section .hoa .tel .Ext label {
          padding-left: 10px; }
        .titleReview-next-section .hoa .tel .Ext div {
          padding-left: 10px; }

.titleReview-nextQuestion {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px; }
  .titleReview-nextQuestion .question {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 40%; }
    .titleReview-nextQuestion .question .rightside-content {
      padding-left: 25px; }
    .titleReview-nextQuestion .question .radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .titleReview-nextQuestion .question .radio .custom-control {
        margin-right: 0px; }
    .titleReview-nextQuestion .question .text {
      width: 100%;
      text-align: right; }
  .titleReview-nextQuestion .sel {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    padding: 5px 0px 5px 0px; }
    .titleReview-nextQuestion .sel label {
      padding-right: 10px; }
  .titleReview-nextQuestion .ip-fields {
    display: -webkit-flex;
    display: flex;
    width: 40%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0px 0px 5px 10px;
    margin-left: 49%; }
    .titleReview-nextQuestion .ip-fields label {
      text-align: right;
      padding-right: 10px;
      -webkit-align-self: center;
              align-self: center; }
    .titleReview-nextQuestion .ip-fields div {
      -webkit-align-self: center;
              align-self: center;
      width: 20%; }

.file-upload {
  margin-left: 11rem;
  margin-bottom: -2rem;
  display: -webkit-flex;
  display: flex; }
  .file-upload .noDocument {
    margin-top: 20px;
    margin-left: 10px; }

.association-document-modal {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: -2rem;
  margin-left: 14rem; }

.reviewActionTable th {
  font-size: small;
  padding: 10px;
  text-align: center !important; }

.reviewActionTable td {
  padding: 5px; }

.titleReview-documents-div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.follow-up-date {
  margin-left: 70%;
  margin-top: -4rem;
  margin-bottom: 2rem;
  display: -webkit-flex;
  display: flex; }
  .follow-up-date h6 {
    margin-top: 9px;
    margin-right: 5px;
    font-weight: bold; }
  .follow-up-date .dateField {
    width: 115px; }

.modal-dialog.municipal-action-modal {
  min-width: 95%; }

.municipal-action-radio {
  display: -webkit-inline-flex;
  display: inline-flex;
  padding-top: calc(0.375rem + 1px); }
  .municipal-action-radio .custom-control {
    margin-right: 0px;
    margin-left: 5px; }

.municipal-violation-button {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.municipal-website {
  padding-top: calc(0.375rem + 1px); }

.municipal-website:hover .tooltiptext {
  visibility: visible; }

.municipal-website .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: gray;
  color: blue;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top: 85%;
  left: 50%;
  margin-left: -140px;
  position: absolute;
  z-index: 1; }

.modal-dialog.municipal-verification-modal {
  min-width: 95%; }

.code-voilation-button {
  padding-left: 15px; }
  @media screen and (min-width: 900px) and (max-width: 1300px) {
    .code-voilation-button {
      padding-left: 30px !important; } }
  @media screen and (min-width: 1300px) and (max-width: 1470px) {
    .code-voilation-button {
      padding-left: 15px !important; } }
  @media screen and (min-width: 1400px) and (max-width: 1700px) {
    .code-voilation-button {
      padding-left: 15px !important; } }

.form-date {
    float: left;
    margin-top: -10%;
}
.to-date {
    float: right;
    margin-top: -6%;
    margin-right:50%
}
.dates {
    margin-top: 1%;
}
.react-bootstrap-table {
    margin-top: 4%;
}
.form-date {
    float: left;
    margin-top: -10%;
}
.to-date {
    float: right;
    margin-top: -6%;
    margin-right:50%
}

.react-bootstrap-table {
    margin-top: 4%;
}
.document-manage {
  display: -webkit-flex;
  display: flex;
  margin-bottom: -2rem; }
  .document-manage .document-title {
    display: -webkit-flex;
    display: flex;
    width: 40%;
    -webkit-align-self: center;
            align-self: center; }
    .document-manage .document-title .document-heading {
      width: 45%;
      text-align: right;
      margin-bottom: 15px;
      margin-top: 10px;
      font-weight: bold; }
    .document-manage .document-title .add-button {
      margin-left: 10px;
      margin-top: -4px; }

.hoa-details .increase-width {
  width: 40%; }

.hoa-details .mutli-select .hoa-multi-select {
  display: -webkit-flex;
  display: flex;
  float: right;
  margin-bottom: 10px;
  width: 100%; }
  .hoa-details .mutli-select .hoa-multi-select .select-box {
    width: 75%; }
  .hoa-details .mutli-select .hoa-multi-select .button {
    padding: 7px; }
    .hoa-details .mutli-select .hoa-multi-select .button button {
      border-radius: 4px;
      width: 70px;
      height: 35px;
      border: none;
      color: white;
      background: gray; }

.create-hoa {
  width: 130px; }

.create-municipal {
  width: 160px; }

.edit-link {
  cursor: pointer; }

.acc-number {
  font-weight: bold;
  font-size: 13px;
  cursor: pointer; }

.hoa_website {
  display: -webkit-flex;
  display: flex;
  overflow-wrap: break-word; }

.hoa_site {
  width: 30%;
  font-weight: bold; }

.assesment_description {
  width: 30%;
  font-weight: bold; }

.payment_site {
  width: 30%;
  font-weight: bold; }

.account_name {
  width: 30%;
  font-weight: bold; }

.account_password {
  width: 30%;
  font-weight: bold; }

.hoa-display-table td {
  width: 250px;
  padding-left: 15px;
  font-size: 12px; }

.hoa-display-table th {
  width: 150px;
  text-align: right !important;
  font-size: 13px !important; }

.municipal-display-table td {
  width: 250px;
  padding-left: 15px;
  font-size: 12px; }

.municipal-display-table th {
  width: 150px;
  text-align: right !important;
  font-size: 13px !important; }

.municipal-display-table .municipal-color-div {
  display: -webkit-flex;
  display: flex; }
  .municipal-display-table .municipal-color-div .dot {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: inline-block; }
  .municipal-display-table .municipal-color-div .municipal-color-text {
    margin-left: 15px; }

.amenity {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-right: 10px; }
  .amenity .amenity-ques-left {
    display: -webkit-flex;
    display: flex;
    width: 32%; }
    .amenity .amenity-ques-left .radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .amenity .amenity-ques-left .radio .custom-control {
        margin-right: 0px; }
    .amenity .amenity-ques-left .text {
      width: 100%;
      text-align: right; }
  .amenity .amenity-ques-right {
    display: -webkit-flex;
    display: flex;
    padding-right: 6%;
    width: 50%; }
    .amenity .amenity-ques-right .radio {
      display: -webkit-inline-flex;
      display: inline-flex; }
      .amenity .amenity-ques-right .radio .custom-control {
        margin-right: 0px; }
    .amenity .amenity-ques-right .text {
      width: 100%;
      text-align: right; }
    .amenity .amenity-ques-right .mailbox_label {
      position: absolute;
      text-align: right; }
    .amenity .amenity-ques-right .mailbox_radio {
      display: -webkit-flex;
      display: flex;
      position: relative;
      left: 400px; }
  .amenity .amenities-file-upload-noDocument {
    display: -webkit-flex;
    display: flex;
    margin-left: 19rem; }
    .amenity .amenities-file-upload-noDocument .amenities-file-upload {
      display: -webkit-flex;
      display: flex; }
      .amenity .amenities-file-upload-noDocument .amenities-file-upload .file-upload-label {
        white-space: nowrap;
        margin-right: 1rem; }
      .amenity .amenities-file-upload-noDocument .amenities-file-upload .noDocument {
        margin-top: 10px; }
      .amenity .amenities-file-upload-noDocument .amenities-file-upload .no-file {
        display: -webkit-flex;
        display: flex; }
      .amenity .amenities-file-upload-noDocument .amenities-file-upload .file-btn {
        margin-top: 0px !important;
        margin-bottom: 0px !important; }
  .amenity .amenity-ques-right-file {
    display: -webkit-flex;
    display: flex;
    margin-left: 13rem; }
    .amenity .amenity-ques-right-file .amenities-file-upload {
      display: -webkit-flex;
      display: flex; }
      .amenity .amenity-ques-right-file .amenities-file-upload .noDocument {
        margin-top: 10px;
        margin-left: 10px;
        white-space: nowrap; }
      .amenity .amenity-ques-right-file .amenities-file-upload .file-upload-label {
        white-space: nowrap;
        margin-right: 1rem; }
      .amenity .amenity-ques-right-file .amenities-file-upload .file-btn {
        margin-top: 0px !important;
        margin-bottom: 0px !important; }

.amenities-document-modal {
  margin-top: -3rem; }

.inspections-info {
  width: 55%; }
  .inspections-info button {
    float: right;
    position: relative;
    bottom: 45px;
    left: 15%;
    border-radius: 4px;
    width: 70px;
    height: 35px;
    border: none;
    color: white;
    background: gray; }

.inspections-intervals .intervals-info {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding: 15px; }
  .inspections-intervals .intervals-info button {
    position: relative;
    left: 2%;
    top: 5px;
    border-radius: 4px;
    width: 70px;
    height: 35px;
    border: none;
    color: white;
    background: gray; }
  .inspections-intervals .intervals-info .heading {
    padding: 10px;
    width: 25%; }
  .inspections-intervals .intervals-info .select-box {
    width: 30%; }

.row-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 20px; }

.right-align {
  white-space: nowrap;
  text-align: right;
  margin-top: 10px; }

.text-yes {
  margin-left: 5px;
  margin-right: 10px; }

.text-no {
  margin-left: 5px;
  margin-right: 10px; }

.date-width {
  width: 120px;
  margin-top: -5px; }

.dropdown-date-row-align {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 10px; }

input[type="radio"].radio-button {
  border: 0px;
  width: 15px;
  height: 15px; }
  input[type="radio"].radio-button .custom-control {
    margin-right: 0px; }

.select-dropdown-width {
  width: 200px;
  margin-right: 20px; }

.display-in-same-row {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px; }

.submit-button-alignment {
  float: right;
  margin-top: 30%; }

.inspection-radio {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-top: 10px; }
  .inspection-radio .custom-control {
    margin-right: 0px; }

.tenantForm input[type="text"],
.tenantForm input[type="number"] {
  width: 60%; }

.tenantForm label {
  margin-right: 3%; }

.tenantForm .styled,
.tenantForm input[type="radio"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin: 0 2%;
  width: 12px;
  height: 12px;
  background: #eeeeee;
  box-shadow: inset 0 0 0 .4em white, 0 0 0 .1em;
  border-radius: 50%;
  transition: .2s;
  cursor: pointer;
  color: #363945; }
  .tenantForm .styled:hover, .tenantForm .styled:checked,
  .tenantForm input[type="radio"]:hover,
  .tenantForm input[type="radio"]:checked {
    background: #363945;
    box-shadow: inset 0 0 0 .6em white, 0 0 0 .2em; }
  .tenantForm .styled:checked,
  .tenantForm input[type="radio"]:checked {
    background: #56be8e;
    box-shadow: inset 0 0 0 .4em white, 0 0 0 .3em #56be8e; }
  .tenantForm .styled:focus,
  .tenantForm input[type="radio"]:focus {
    outline: 0; }

.modal-dialog.municipal-modal {
  min-width: 90%; }

.modal-dialog.municipal-add-modal {
  min-width: 80%; }

.poc-style {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 20px; }

.add-municipality {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .add-municipality .add-model-left-portion {
    width: 60%;
    padding: 10px; }
  .add-municipality .add-model-right-portion {
    width: 40%;
    padding: 10px; }
  .add-municipality .add-poc {
    color: white;
    display: -webkit-flex;
    display: flex;
    border: 1px solid white;
    background: #53bf53;
    border-radius: 20px;
    width: 30%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer; }
    .add-municipality .add-poc .text {
      padding: 6px; }

.municipal-buttons {
  display: -webkit-flex;
  display: flex;
  margin-left: 105px; }
  .municipal-buttons .municipal-addoffice-button {
    margin-bottom: 0px; }

.municipal-submit {
  width: 50%; }
  .municipal-submit .municipal-submit-button {
    float: right; }

.municipal-office-table {
  width: 90%;
  margin-left: 106px; }
  .municipal-office-table .react-bootstrap-table {
    margin-top: 1%; }

.propertyDisplaycode {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 0px;
  padding-right: 10px;
  width: 100%; }
  .propertyDisplaycode .propertyinfo-leftSide {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%; }
    .propertyDisplaycode .propertyinfo-leftSide .rightside-content {
      padding-left: 25px; }
    .propertyDisplaycode .propertyinfo-leftSide .questionHeading {
      display: -webkit-inline-flex;
      display: inline-flex;
      font-size: 12px !important;
      margin-left: 1rem; }
    .propertyDisplaycode .propertyinfo-leftSide .answerText {
      width: 80%;
      text-align: right;
      font-weight: bold;
      font-size: 13px !important; }
  .propertyDisplaycode .propertyinfo-rightSide {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50%; }
    .propertyDisplaycode .propertyinfo-rightSide .rightside-content {
      padding-left: 25px; }
    .propertyDisplaycode .propertyinfo-rightSide .questionHeading {
      display: -webkit-inline-flex;
      display: inline-flex;
      font-size: 12px !important;
      margin-left: 1rem;
      width: 35% !important; }
    .propertyDisplaycode .propertyinfo-rightSide .answerText {
      width: 60% !important;
      text-align: right;
      font-weight: bold;
      font-size: 13px !important; }

.noHoaExist {
  font-weight: bold;
  margin-top: 6rem;
  text-align: center; }

.property-data {
  margin-top: 35px;
  padding: 1rem 2rem; }

.noLeasingExist {
  font-weight: bold;
  text-align: center; }

.notes-div .react-bootstrap-table .table {
  table-layout: fixed;
  word-break: break-all; }

.back {
  position: absolute;
  border: none;
  border-radius: 10px;
  width: 4rem;
  height: 30px;
  /* color: white; */
  background: gray; }
  .back a {
    color: white; }
  .back a:hover {
    color: white;
    text-decoration: none; }

.property-manage {
  display: -webkit-flex;
  display: flex;
  margin-right: 15px;
  margin-bottom: 10px; }
  .property-manage .page-title {
    width: 35%;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 10px; }
    .property-manage .page-title .page-heading {
      margin-bottom: 15px;
      margin-top: 15px; }
  .property-manage .property-info-table {
    width: 65%; }
    .property-manage .property-info-table .property-details .react-bootstrap-table {
      margin: 0; }
    .property-manage .property-info-table .property-details > div button {
      position: absolute;
      top: -15px;
      right: 20px; }

.property-details .property-data {
  display: -webkit-flex;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px; }
  .property-details .property-data .first {
    width: 29%;
    padding-left: 0rem; }
  .property-details .property-data .center {
    width: 33%;
    line-height: 10px; }
    .property-details .property-data .center .child {
      display: -webkit-flex;
      display: flex; }
      .property-details .property-data .center .child .property-label {
        width: 120px;
        text-align: justify; }
  .property-details .property-data .last {
    width: 36%;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    line-height: 10px;
    padding-left: 30px; }
    .property-details .property-data .last .child {
      display: -webkit-flex;
      display: flex; }
      .property-details .property-data .last .child .property-label {
        width: 150px;
        text-align: justify; }
        @media screen and (min-width: 900px) and (max-width: 1300px) {
          .property-details .property-data .last .child .property-label {
            width: 280px; } }
        @media screen and (min-width: 1345px) and (max-width: 1470px) {
          .property-details .property-data .last .child .property-label {
            width: 200px; } }
        @media screen and (min-width: 1472px) and (max-width: 1700px) {
          .property-details .property-data .last .child .property-label {
            width: 170px; } }
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
          .property-details .property-data .last .child .property-label {
            width: 300px; } }
      .property-details .property-data .last .child .green {
        text-transform: capitalize;
        color: green;
        margin-bottom: 0.5rem;
        width: 20rem; }
      .property-details .property-data .last .child .red {
        text-transform: capitalize;
        color: red;
        margin-bottom: 0.5rem;
        width: 10rem; }
    .property-details .property-data .last button {
      font-size: 14px;
      padding: 4px;
      /* padding-bottom: 10px; */
      margin-bottom: 4px;
      float: right;
      margin-top: -40px; }

.modal-dialog.lienRelease-modal {
  min-width: 80%; }

.lien-source-div {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px); }

.dashboard_scope_div {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  margin: 5px; }
  .dashboard_scope_div .dashboard_scope_checkbox_div {
    display: -webkit-flex;
    display: flex;
    width: 30%; }
  .dashboard_scope_div .checkbox_input {
    width: 50px;
    margin-left: 10px; }
  .dashboard_scope_div .checkbox_div_data {
    margin: 5px;
    width: 300px;
    font-size: 16px !important; }

.users {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .users .page-title {
    font-size: 2rem;
    font-weight: 600; }

.graph-data {
  padding-top: 20px; }
  .graph-data .form {
    margin-left: 10%; }

.reduce-width {
  width: 10% !important;
  padding: 0.5 rem; }

.notes-width {
  width: 80% !important;
  word-break: break-all; }

.address-width {
  width: 15%; }

.table-header-cell-alignment {
  text-align: left !important; }

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret,
.react-bootstrap-table th .order > .dropdown > .caret,
.react-bootstrap-table th .order > .dropup > .caret {
  margin-bottom: 0;
  margin-top: 0; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropup .caret {
  border-bottom: 4px dashed;
  border-top: 0;
  content: ''; }

.hoaName-width {
  width: 20% !important;
  padding: 0.0rem 0.4rem 0.0rem 0.4rem !important;
  vertical-align: middle !important; }

.hoa-address-width {
  width: 20% !important;
  padding: 0.0rem 0.4rem 0.0rem 0.4rem !important;
  vertical-align: middle !important; }

.hoa-zip-width {
  padding: 0.0rem 0.4rem 0.0rem 0.4rem !important;
  vertical-align: middle !important; }

.hoa-status-width {
  width: 25% !important;
  padding: 0.0rem 0.4rem 0.0rem 0.4rem !important;
  vertical-align: middle !important; }

.hoa-notes-width {
  padding: 0.0rem 0.4rem 0.0rem 0.4rem !important;
  vertical-align: middle !important; }

.hoaTableStatusMainDiv {
  display: -webkit-flex;
  display: flex;
  height: 30px; }

.hoaTableStatus {
  border: 1px solid black;
  width: 15%; }

.propertyReview-status-width {
  width: 20% !important;
  padding: 0.0rem 0.4rem 0.0rem 0.4rem !important;
  vertical-align: middle !important; }

.propertyReviewStatusMainDiv {
  display: -webkit-flex;
  display: flex;
  height: 30px; }

.propertyReviewStatus {
  border: 1px solid black;
  width: 25%; }

.flex-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin-top: 0.5rem;
  width: 70%; }

.flex-container > div {
  background-color: #00aff0;
  width: 15%;
  margin: 7px;
  text-align: center;
  font-size: 18px !important;
  padding: 5px;
  box-shadow: 5px 5px #888888;
  color: white;
  border-radius: 5px; }

.flex-heading {
  font-size: 16px !important; }

.horizontalClass {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0.5px solid white !important; }

.card-firstRow {
  cursor: pointer; }

.middleRow {
  display: -webkit-flex;
  display: flex;
  height: 33px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .middleRow .firstRowData {
    width: 50%;
    cursor: pointer;
    border-right: 0.5px solid white; }
  .middleRow .secondRowData {
    width: 50%;
    cursor: pointer; }

.tierTwoClass {
  width: 95%;
  margin-top: 2%;
  text-align: center; }

.tierTwoDivClass {
  border: 1px solid;
  display: -webkit-flex;
  display: flex; }
  .tierTwoDivClass .association {
    width: 20%;
    border-right: 1px solid; }
    .tierTwoDivClass .association .firstRow {
      font-size: 1.5rem;
      border-bottom: 1px solid;
      cursor: pointer; }
    .tierTwoDivClass .association .firstRow:hover {
      font-size: 1.5rem;
      border-bottom: 1px solid;
      cursor: pointer;
      background-color: blue !important; }
    .tierTwoDivClass .association .middleRow {
      display: -webkit-flex;
      display: flex;
      height: 33px; }
      .tierTwoDivClass .association .middleRow .firstRowData {
        width: 50%;
        font-size: 1.5rem;
        margin-top: 7px;
        cursor: pointer; }
      .tierTwoDivClass .association .middleRow .firstRowData:hover {
        width: 50%;
        font-size: 1.5rem;
        margin-top: 7px;
        cursor: pointer;
        background-color: blue !important; }
      .tierTwoDivClass .association .middleRow .secondRowData {
        width: 50%;
        font-size: 1.5rem;
        border-left: 1px solid;
        margin-top: 7px;
        cursor: pointer; }
      .tierTwoDivClass .association .middleRow .secondRowData:hover {
        width: 50%;
        font-size: 1.5rem;
        border-left: 1px solid;
        margin-top: 7px;
        cursor: pointer;
        background-color: blue !important; }
    .tierTwoDivClass .association .thirdRow {
      border-top: 1px solid;
      font-weight: bold;
      height: 30px;
      font-size: 13px !important; }
    .tierTwoDivClass .association .association {
      border-top: 1px solid; }

.associationReview {
  background-color: gray;
  border-top: 0px !important;
  border: 1px solid;
  color: white; }

.tierThreeClass {
  width: 95%;
  margin-top: 2%;
  text-align: center; }

.tierThreeDivClass {
  border: 1px solid;
  display: -webkit-flex;
  display: flex; }
  .tierThreeDivClass .association {
    width: 20%;
    border-right: 1px solid; }
    .tierThreeDivClass .association .firstRow {
      font-size: 1.5rem;
      border-bottom: 1px solid;
      cursor: pointer; }
    .tierThreeDivClass .association .middleRow {
      display: -webkit-flex;
      display: flex;
      height: 33px; }
      .tierThreeDivClass .association .middleRow .firstRowData {
        width: 50%;
        font-size: 1.5rem; }
      .tierThreeDivClass .association .middleRow .secondRowData {
        width: 50%;
        font-size: 1.5rem;
        border-left: 1px solid; }
    .tierThreeDivClass .association .thirdRow {
      border-top: 1px solid;
      font-weight: bold;
      height: 30px;
      font-size: 13px !important; }
    .tierThreeDivClass .association .association {
      border-top: 1px solid; }

.filter-input {
	width: 250px;
}

.search-btn {
	margin-top: 25px;
    margin-left: 10px!important;
}

.btn-group.actions {
	margin-top: -7%!important;
}
.sidebar-section {
  height: 100%;
  width: 17%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 10px; }

.sidebar-section .sidebar {
  width: 245px;
  position: fixed;
  left: 0;
  padding: 102px 0rem .5rem;
  height: 100%;
  overflow: hidden;
  z-index: 19;
  background-color: black; }
  @media screen and (min-width: 900px) and (max-width: 1300px) {
    .sidebar-section .sidebar {
      width: 220px; } }
  @media screen and (min-width: 1300px) and (max-width: 1470px) {
    .sidebar-section .sidebar {
      width: 245px; } }
  @media screen and (min-width: 1400px) and (max-width: 1700px) {
    .sidebar-section .sidebar {
      width: 250px; } }

.sidebar-section .sidebar .scrollbar-inner {
  position: relative;
  height: 100%; }

.sidebar-section .sidebar .scrollbar-inner .customDropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer; }

.sidebar-section .sidebar .scrollbar-inner .customDropdown .button {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none; }

.sidebar-section .sidebar .scrollbar-inner .customDropdown .button svg {
  margin-left: 45%; }

.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content {
  display: none;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%; }

.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content > .link {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.sidebar-section .sidebar .scrollbar-inner .customDropdown .unhide {
  display: block; }

.sidebar-section .sidebar .scrollbar-inner .customDropdown:hover .button {
  background-color: #818181; }

.sidebar-section .sidebar .scrollbar-inner .customDropdown .dropdown-content > .link:hover {
  background-color: #ddd; }

.sidebar-section .sidebar .scrollbar-inner .navigation {
  padding: 0;
  list-style: none; }

.sidebar-section .sidebar .scrollbar-inner .navigation a:hover {
  text-decoration: none; }

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item {
  font-size: 16px;
  padding: 10px 15px;
  color: white; }

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item a {
  text-decoration: none;
  color: white; }

.sidebar-section .sidebar .scrollbar-inner .navigation .list-item:hover {
  text-decoration: none !important;
  background-color: #818181 !important;
  cursor: pointer; }

.list-item {
  color: white;
  background-color: #020202; }

.dropbtn {
  background-color: #000000;
  min-width: 240px;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer; }

.sidebar-dropdown-header {
  position: relative;
  display: block; }

.dropdown-content {
  display: none;
  position: absolute;
  top: 0px;
  right: 50;
  background-color: #f9f9f9;
  min-width: 160px;
  text-align: center;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 100px; }

.dropdown-content a {
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.dropdown-content a:hover {
  background-color: #818181 !important;
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.sidebar-dropdown-header:hover .dropdown-content {
  display: block; }

.sidebar-dropdown-header:hover .dropbtn {
  background-color: #818181; }

.modal-dialog.quickStats-modal {
  min-width: 70%;
  margin-top: 0rem; }
  .modal-dialog.quickStats-modal .association-status {
    margin-left: 20rem;
    font-size: 13px;
    font-weight: bold; }
  .modal-dialog.quickStats-modal .association-status-value {
    font-size: 22px;
    color: #F32C52;
    font-weight: bold; }

.quickStatusTable {
  margin-top: 1rem;
  width: 70%;
  margin-left: 10rem; }
  .quickStatusTable .quickStatusTableRow {
    text-align: center !important;
    border: 1px solid black;
    font-weight: bold; }
    .quickStatusTable .quickStatusTableRow td {
      font-size: 22px;
      color: #F32C52; }
    .quickStatusTable .quickStatusTableRow th {
      font-size: 14px; }
  .quickStatusTable .quickStatusTableActiveRow {
    text-align: center !important;
    border: 1px solid black;
    font-weight: bold; }
    .quickStatusTable .quickStatusTableActiveRow td {
      font-size: 22px;
      color: #F32C52; }
    .quickStatusTable .quickStatusTableActiveRow th {
      font-size: 14px; }

.react-bootstrap-table .table {
    /* table-layout: auto !important; */
}

.react-bootstrap-table {
    font-size: 12px !important;
}

.btn-group.actions {
    position: relative;
    float: right;
    margin-top: -4%;
    margin-bottom: 1%;
    right: 0%;
}
.btn-group.addactions {
    position: relative;
    float: right;
    margin-top: -65px;
    margin-bottom: 1%;
    right: 30px;
    width: 75px;
}
.btn-group.actions button {
    border-radius: 5px !important;
    margin-right: 10px;
}

.table-actions button {
    margin-right: 5px;
}

input[type="file"] {
    height: 58px;
}
.email-access-general-info .page-header {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  min-height: 70px;
  padding: 0.5rem 2rem;
  z-index: 20;
  display: -ms-flexbox;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15); }

.email-access-general-info .property-data {
  margin-top: 75px;
  padding: 1rem 2rem;
  text-align: right; }

.email-access-general-info .general-component {
  padding: 0rem 1rem; }
  .email-access-general-info .general-component .listdiv .lease-info .buttons .update-btn {
    position: absolute;
    top: 280px;
    right: 2%; }
  .email-access-general-info .general-component .reduce-margin {
    margin: 0px; }
  .email-access-general-info .general-component .general-info-tab {
    border: none !important; }
  .email-access-general-info .general-component .general-info-tab li.nav-item {
    background-color: darkgrey;
    min-width: 14.1%;
    text-align: center; }
    .email-access-general-info .general-component .general-info-tab li.nav-item.active {
      margin-top: -0.5%;
      background-color: lightblue;
      border: none; }
    .email-access-general-info .general-component .general-info-tab li.nav-item .nav-link {
      color: black; }
  .email-access-general-info .general-component .general-info-tab li.nav-item .nav-link.active {
    background-color: lightblue;
    border-color: lightblue;
    border: none !important; }

.guestDisplaycode {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 0px;
  padding-right: 10px;
  width: 75%; }
  .guestDisplaycode .question-leftSide {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 35%; }
    .guestDisplaycode .question-leftSide .rightside-content {
      padding-left: 25px; }
    .guestDisplaycode .question-leftSide .questionHeading {
      display: -webkit-inline-flex;
      display: inline-flex;
      font-size: 12px !important;
      margin-left: 1rem; }
    .guestDisplaycode .question-leftSide .answerText {
      width: 70%;
      text-align: right;
      font-weight: bold;
      font-size: 13px !important; }
  .guestDisplaycode .question-rightSide {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 60%; }
    .guestDisplaycode .question-rightSide .rightside-content {
      padding-left: 25px; }
    .guestDisplaycode .question-rightSide .questionHeading {
      display: -webkit-inline-flex;
      display: inline-flex;
      font-size: 12px !important;
      margin-left: 1rem; }
    .guestDisplaycode .question-rightSide .answerText {
      width: 45%;
      text-align: right;
      font-weight: bold;
      font-size: 13px !important; }

.noHoaExist {
  font-weight: bold;
  margin-top: 6rem;
  text-align: center; }

.hoatableHeading {
  width: 13% !important; }

.hoatableData {
  width: 28%; }

.property-data {
  margin-top: 35px;
  padding: 1rem 2rem; }

.noLeasingExist {
  font-weight: bold;
  text-align: center; }

.listCompButton {
  position: relative;
  float: right;
  margin-top: -5%;
  margin-right: 2%;
  width: 65%; }
  .listCompButton button {
    float: right; }

.modal-dialog.businessUnit-modal {
  min-width: 50%; }

.add-business-model-poc {
  width: 100%;
  padding: 10px; }
  .add-business-model-poc .add-poc {
    color: white;
    display: -webkit-flex;
    display: flex;
    border: 1px solid white;
    background: #53bf53;
    border-radius: 20px;
    width: 25%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer; }
    .add-business-model-poc .add-poc .text {
      padding: 6px; }

.location:hover {
  color: blue;
  text-decoration: underline; }

.modal-dialog.businessUnitLocation-modal {
  min-width: 95%; }

.counties_list_main_div {
  width: 100%; }
  .counties_list_main_div .county_data {
    width: 100%;
    display: -webkit-flex;
    display: flex; }
  .counties_list_main_div .checkbox_div {
    width: 185px;
    display: -webkit-flex;
    display: flex;
    margin: 5px; }
  .counties_list_main_div .checkbox_input {
    width: 40px;
    margin-left: 10px; }
  .counties_list_main_div .checkbox_div_data {
    margin: 5px;
    width: 300px;
    font-size: 16px !important; }

.modal-dialog.law-firm-modal {
  min-width: 90%; }

.poc-style {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 20px; }

.add-law_firm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .add-law_firm .add-model-left-portion {
    width: 60%;
    padding: 10px; }
  .add-law_firm .add-model-right-portion {
    width: 40%;
    padding: 10px; }
  .add-law_firm .add-poc {
    color: white;
    display: -webkit-flex;
    display: flex;
    border: 1px solid white;
    background: #53bf53;
    border-radius: 20px;
    width: 25%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer; }
    .add-law_firm .add-poc .text {
      padding: 6px; }

.modal-dialog.order-history {
  min-width: 60%; }
  .modal-dialog.order-history .table-head {
    font-size: 15px;
    padding-bottom: 6px;
    font-weight: 600; }
  .modal-dialog.order-history .heading {
    padding-right: 50px; }

.title-order-search-btn {
  margin-top: 0px !important; }

