.modal-dialog.businessUnitLocation-modal {
    min-width: 95%;
  } 

  .counties_list_main_div{
      width: 100%;
      .county_data{
        width: 100%;
        display: flex;
      }
    .checkbox_div  {
        width: 185px;
        display: flex;
        margin: 5px;
    }
      .checkbox_input{
           width: 40px;
        margin-left: 10px;
    }
    .checkbox_div_data{
        margin: 5px;
        width: 300px;
        font-size: 16px !important;
    }

  }