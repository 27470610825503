.header {
    height: 72px;
    padding: 0 2rem;
    z-index: 20;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .15);
}

.header::after {
    display: block;
    clear: both;
    content: "";
}

.header .header-logo {
    position: relative;
    background: white;
    background-position: center;
}

.header .user-div {
    height: 58px;
    display: flex;
    position: absolute;
    right: 0;
    color: black;
}

.header .user-div .users-sub-div p {
    font-weight: bold;
}

.header .user-div .userimg img {
    height: 50px;
    cursor: pointer;
}



.header .user-div .users-sub-div .logout-btn {
    cursor: pointer;
}
 .searchBar{
    width: 40%;
    margin-top: 1rem;
    margin-left: 10rem;
    @media screen and ( min-width: 900px ) and ( max-width: 1300px ) {
        width: 35%;
      }
    @media screen and ( min-width: 1300px ) and ( max-width: 1470px ) {
      
        width: 40%;
      }
      @media screen and ( min-width: 1400px ) and ( max-width: 1700px ) {
        width: 40%;
      }
    //   @media screen and ( min-width: 1024px ) and ( max-width: 1200px ) {
    //     width: 40%;
    //   }
  
}
.search-btn{
    margin: auto !important;
}

.quickStatus Button{
    background: #F32C52;
    border-radius: 0px !important;
    color: white;
    font-weight: bold;
    border-color: #F32C52;
    margin-top: 2rem;
}

.quickStatus Button:hover{
    background: #F32C52;
    border-radius: 0px #F32C52 !important;
    color: white;
    font-weight: bold;
    border-color: #F32C52;
    margin-top: 2rem;
}

.search_type_selection_div{
    width: 10%;
    margin-left: 5%;

    
}



