.guestDisplaycode {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 0px;
  padding-right: 10px;
  width: 75%;
  
.question-leftSide {
  display: inline-flex;
  // justify-content: flex-end;
  width: 35%;
  .rightside-content {
    padding-left: 25px;
  }
  .questionHeading {
    display: inline-flex;
    font-size: 12px !important;
    margin-left: 1rem;
  }
  .answerText{
    width: 70%;
    text-align: right;
    font-weight: bold;
    font-size: 13px !important;
  }
}    
.question-rightSide {
  display: inline-flex;
  // justify-content: flex-end;
  width: 60%;
  .rightside-content {
    padding-left: 25px;
  }
  .questionHeading {
    display: inline-flex;
    font-size: 12px !important;
    margin-left: 1rem;
  }
  .answerText {
    width: 45%;
    text-align: right;
    font-weight: bold;
    font-size: 13px !important;
  }
}    
}

  .noHoaExist{
    font-weight: bold;
    margin-top: 6rem;
    text-align: center;
  }

  .hoatableHeading{
    width: 13% !important;
  }
  .hoatableData{
    width: 28%;
  }

  .property-data {
    margin-top: 35px;
    padding: 1rem 2rem;
    //text-align: right;
  }

  .noLeasingExist{
    font-weight: bold;   
    text-align: center;
  }
