.content {
    margin-top: 11vh;
    margin-left: 18%;
}

.content.searchtable{
    margin-top: 24vh;
}

.content .content-title {
    padding: 10px 0px 0px 0px;
    position: relative;
}

.content .content-title .page-title {
    font-size: 2rem;
    font-weight: 600;
}

.content .content-title .page-heading {
    margin-bottom: 15px;
}

.content .content-title .search-btn {
    margin: auto !important;
}
/* .utility{
    width: 250px;
    display: flex;
    height: 50px;
    padding: 4px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.utilityItem {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 10px;
    border-radius: 50%;
    color: #dee4e7;
    text-shadow: 1px 1px black;
    font-size: 12;
    font-family: "Audiowide", sans-serif;
}
.utilityItem.electricity{
    background-color: red;
}
.utilityItem.water{
    background-color: yellow;
}
.utilityItem.gas{
    background-color: black;
}
.utilityItem.sewage{
    background-color: green;
}
.utilityItem.trash{
    background-color: brown;
} */