
.notes-width{
 width: 80% !important;
 word-break: break-all;
 //text-align: center !important;
}
.header-cell-alignment{
  text-align: left !important;
}

.documentTab {
  width: 95%;
}
.hoa-documentTab {
  width: 100%;
  margin-top: 1.5rem;
}



