.modal-dialog.lienAction-modal {
    min-width: 95%;
  }

  .radio-button {
    display: flex;
    //margin-left: 15px;
    .custom-control {
      margin-right: 0px;
      margin-left: 5px;
    }
  }

  .title-lien-radio-col{
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
  }