.modal-dialog.municipal-modal {
    min-width: 90%;
  }

  .modal-dialog.municipal-add-modal
  {
    min-width: 80%;
  }
  
  .poc-style {
    border: 1px solid #ccc;
    padding: 10px 15px;
    margin-bottom: 20px;
  }
  
  .add-municipality {
    display: flex;
    justify-content: space-between;
  
    .add-model-left-portion {
      width: 60%;
      padding: 10px;
    }
  
    .add-model-right-portion {
      width: 40%;
      padding: 10px;
    }
    .add-poc {
      color: white;
      display: flex;
      border: 1px solid white;
      background: #53bf53;
      border-radius: 20px;
      width: 30%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .text {
        padding: 6px;
      }
    }
  }
.municipal-buttons{
  display: flex;
  margin-left: 105px;
  
  .municipal-addoffice-button{
    margin-bottom: 0px;
  }}

  .municipal-submit{
    width: 50%;
  .municipal-submit-button{
    float: right;
   // margin-bottom: 0px;
  }
  }

  .municipal-office-table{
    width: 90%;
    margin-left: 106px;
  
  .react-bootstrap-table{
    margin-top: 1%;
  }}
  