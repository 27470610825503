.modal-dialog.hearing-modal {
    min-width: 50%;
  } 

  .modal-dialog.hearing-listing-modal {
    min-width: 80%;
  } 

  .modal-dialog.attendees-modal {
    min-width: 50%;
  } 
 
 .code-violation-hearing-radio-button {
    padding-top: calc(0.375rem + 1px);
    display: inline-flex;
    margin-left: 15px;
    .custom-control {
      margin-right: 0px;
      margin-left: 5px;
    }
  }

  .hearing-buttons{
      margin-top: 0px ;
      margin-bottom: 0px !important;
  }
  
  .attendees_table {
    tr, th, td{ border: 1px solid black;
   cursor: pointer; }
  }
