.follow-up-date{
    margin-left: 70%;
    margin-top: -4rem;
    margin-bottom: 2rem;
    display: flex;
    h6{
        margin-top: 9px;
        margin-right: 5px;
        font-weight: bold;
    }
    .dateField{
        width: 115px;
    }
}