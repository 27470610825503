.propertyDisplaycode {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 0px;
    padding-right: 10px;
    width: 100%;
    
  .propertyinfo-leftSide {
    display: inline-flex;
    // justify-content: flex-end;
    width: 50%;
    .rightside-content {
      padding-left: 25px;
    }
    .questionHeading {
      display: inline-flex;
      font-size: 12px !important;
      margin-left: 1rem;
    }
    .answerText{
      width: 80%;
      text-align: right;
      font-weight: bold;
      font-size: 13px !important;
    }
  }    
  .propertyinfo-rightSide {
    display: inline-flex;
    // justify-content: flex-end;
    width: 50%;
    .rightside-content {
      padding-left: 25px;
    }
    .questionHeading {
      display: inline-flex;
      font-size: 12px !important;
      margin-left: 1rem;
      width: 35% !important;
    }
    .answerText {
      width: 60% !important;
      text-align: right;
      font-weight: bold;
      font-size: 13px !important;
    }
  }    
  }
  
    .noHoaExist{
      font-weight: bold;
      margin-top: 6rem;
      text-align: center;
    }
  
    .property-data {
      margin-top: 35px;
      padding: 1rem 2rem;
      //text-align: right;
    }
  
    .noLeasingExist{
      font-weight: bold;   
      text-align: center;
    }
  