// Use saas syntax in this page

input[type="radio"].radio-button {
  width: 5px;
  height: 5px;
}
.right-align {
  text-align: right !important;
}
div.container-fluid {
  font-size: 12px;
  .horizonal-line-leasing {
    width: 101%;
    position: relative;
    right: 16px;
  }
  .submit-button-align {
    text-align: center;
  }
}
.no-Document {
  position: relative;
  top: 10px;
}

.display-in-same-row {
  display: flex;
  flex-direction: row;
  .text-yes {
    position: relative;
    right: 15px;
    top: 3px;
  }
  .text-no {
    position: relative;
    right: 30px;
    top: 3px;
  }
  .gap-between-yes-no {
    position: relative;
    right: 15px;
  }
  .no-wrap {
    white-space: nowrap;
  }
}

.left-align {
  text-align: left !important;
}

.amenities-file-upload {
  display: flex;

  .noDocument {
    margin-top: 10px;
    margin-left: 10px;
  }
}
.upload-button-align {
  position: relative;
  bottom: 12px;
}

//The name text was overriding other page CSS so i have renamed it
// Use saas syntax to prevent these kind of errors
.text_align {
  white-space: nowrap;
  text-align: right !important;
}
.township-address-leasing {
  position: relative;
  top: 5px;
  .textbox-width {
    width: 170px;
  }
}
.rental-fee-margin-leasing {
  margin-left: 45px;
  .fee-sub-div {
    position: relative;
    top: 9px;
    left: 70px;
    .textbox-width {
      width: 100px;
    }
  }
}
.leasing-date-styling {
  position: relative;
  right: 2px;
  .sub-text-span {
    position: relative;
    top: 17px;
    margin-left: 20px;
  }
  .date-span {
    position: absolute;
    width: 100px;
    top: 10px;
  }
}
.move-in-fee-styling {
  position: relative;
  left: 58px;
  .move-in-sub-ques {
    position: relative;
    top: 5px;
  }
  .move-in-fee-textbox {
    position: relative;
    top: 8px;
    width: 100px;
  }
}
.file-upload-button-div {
  display: flex;
  position: relative !important;
  //right: 20px;
  top: 5px;
  width: 35%;
  .file-upload-button {
    margin-top: 0px;
    margin-bottom: 5px;
    width: 55% !important;
  }
}

.max-length-board-styling {
  position: relative;
  right: 40px;
  bottom: 5px;
  .max-length-sub-ques {
    position: relative;
    top: 12px;
  }
  .max-length-days {
    position: relative;
    top: 12px;
  }
  .textbox-width {
    position: relative;
    width: 60px;
    top: 12px;
  }
}

.min-month-unit-own-leasing-width {
  width: 60px;
}

.time-unit-lease-year-div {
  margin-left: 45px;
  .textbox-width {
    width: 60px;
  }
}
.lease-document-table {
  width: 100%;
  position: relative;
  text-align: justify;
  // margin: 0 50px 0 100px;
  // left: 5%;
  margin-right: 2rem;
  overflow-x: hidden;
}

div.react-datepicker-popper > div {
  position: relative;
  right: 49px;
}

::placeholder {
  color: grey;
}
// This CSS is overlapping in other pages . Please use saas syntax .
// .text {
//   white-space: nowrap;
//   text-align: right !important;
// }

.lease-radio-question {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 0px;
  padding-right: 10px;
  .lease-question {
    display: inline-flex;
    // justify-content: flex-end;
    width: 46%;

    .rightside-content {
      padding-left: 25px;
    }

    .lease-radio {
      display: inline-flex;
      .custom-control {
        margin-right: 0px;
        margin-top: -2px;
      }
    }
    .lease-text {
      width: 100%;
      text-align: right;
    }
  }

  .input-fields {
    display: inline-flex;
    width: 48%;
    justify-content: flex-end;
    padding: 0px 20px 5px 10px;
    margin-right: 4rem;
    label {
      text-align: right;
      padding-right: 10px;
      align-self: center;
    }
    div {
      align-self: center;
    }
    input {
      width: 30%;
    }

    .date-picker {
      width: 100%;
    }
    .react-datepicker-wrapper {
      width: 20%;
      .react-datepicker__input-container {
        input {
          text-align: center;
        }
      }
    }
  }
}
