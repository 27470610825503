.modal-dialog.quickStats-modal {
    min-width: 70%;
    margin-top: 0rem;
    .association-status{
      margin-left: 20rem;
      font-size: 13px;
      font-weight: bold;
    }
    
      .association-status-value{
        font-size: 22px;
        color: #F32C52;
        font-weight: bold;
    
      }
  }

  .quickStatusTable  {
    margin-top: 1rem;
    width: 70%;
    margin-left: 10rem;
      .quickStatusTableRow {
        text-align: center !important;
    border: 1px solid black;
    font-weight: bold;
    
    td{
      font-size: 22px;
      color: #F32C52;
    }
    th{
      font-size: 14px;
    }
      }
      .quickStatusTableActiveRow{
        text-align: center !important;
        border: 1px solid black;
        font-weight: bold;
        
        td{
          font-size: 22px;
          color: #F32C52;
        }
        th{
          font-size: 14px;
        }

      }
  }

  

