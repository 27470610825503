.document-manage {
    display: flex;  
    margin-bottom: -2rem;
    .document-title {
        display: flex;  
        width: 40%;
        align-self: center;      

        .document-heading {
            width: 45%;
            text-align: right;
            margin-bottom: 15px;
            margin-top: 10px;            
            font-weight: bold;
           
        }
        .add-button{
            margin-left: 10px;
            margin-top: -4px;
        }
    }
}