
.listCompButton {
    position: relative;
    float: right;
    margin-top: -5%;
    margin-right: 2%;
    width: 65%;

    button {
        float: right;
    }
}