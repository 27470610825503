.modal-dialog.order-history {
  min-width: 60%;
  .table-head{
    font-size: 15px;
    padding-bottom: 6px;
    font-weight: 600;
  }
  .heading{
    padding-right: 50px;
  }
}

.title-order-search-btn{
  margin-top: 0px !important;
}