.modal-dialog.municipal-action-modal {
  min-width: 95%;
}
.municipal-action-radio {
    display: inline-flex;
    padding-top: calc(0.375rem + 1px);
    .custom-control {
      margin-right: 0px;
      margin-left: 5px;
    }
  }

  .municipal-violation-button{
      margin-top: 0px !important;
      margin-bottom: 0px !important;
  }
  .municipal-website{
    padding-top: calc(0.375rem + 1px);
  }
  .municipal-website:hover .tooltiptext {
    visibility: visible;
  }
  .municipal-website .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: gray;
    color:blue;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    top: 85%;
    left: 50%;
    margin-left: -140px;
    position: absolute;
    z-index: 1;
  }

  