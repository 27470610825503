.modal-dialog.codeViolation-modal {
  min-width: 95%;
}
.modal-content {
  min-width: 50%;
}

.code-violation-radio-button {
  display: inline-flex;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  .custom-control {
    margin-right: 0px;
    margin-left: 5px;
  }
}
.code-violation-text {
  width: 100%;
  text-align: right;
  font-weight: bold;
}

.case-number {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}
.case-number:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.code-violation-date {
  width: 115px;
}
// .customDropdownIcon-codeviolation {
//   position: relative;
//   float: right;
//   margin-top: -28px;
//   padding-right: 10px;
//   color: black;
// }
