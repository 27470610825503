.back {
  position: absolute;
  border: none;
  border-radius: 10px;
  width: 4rem;
  height: 30px;
//   top: -4px;
  /* color: white; */
  background: gray;

  a {
    color: white;
  }

  a:hover {
    color: white;
    text-decoration: none;
  }
}

.property-manage {
  display: flex;
  margin-right: 15px;
  margin-bottom: 10px;

  .page-title {
    width: 35%;
    align-self: center;
    margin-top: 10px;

    .page-heading {
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .property-info-table {
    width: 65%;

    .property-details {
      .react-bootstrap-table {
        margin: 0;
      }

      & > div {
        button {
          position: absolute;
          top: -15px;
          right: 20px;
        }
      }
    }
  }
}

// need to refactor

.property-details {
  .property-data {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 10px;
    .first {
      width: 29%;
      padding-left: 0rem;
    }
    .center {
      width: 33%;
      line-height: 10px;
      .child {
        display: flex;
        .property-label {
          width:  120px;
          text-align: justify;
         
        }
       
      }
    }
    .last {
      width: 36%;
      align-self: flex-start;
      line-height: 10px;
      padding-left: 30px;
      .child {
        display: flex;
        .property-label{
          width: 150px;
          text-align: justify;
          @media screen and (min-width: 900px) and (max-width: 1300px) {
            width: 280px;
          }
          @media screen and (min-width: 1345px) and (max-width: 1470px) {
            width: 200px;
          }
          @media screen and (min-width: 1472px) and (max-width: 1700px) {
            width:  170px;
          }
          @media screen and (min-width: 1024px) and (max-width: 1200px) {
            width:  300px;
          }
         
        }
        
      .green {
        text-transform: capitalize;
        color: green;
        //margin-top: 5px;
        margin-bottom: 0.5rem;
        width: 20rem;
      }
      .red {
        text-transform: capitalize;
        color: red;
       // margin-top: 5px;
        margin-bottom: 0.5rem;
        width: 10rem;
      }
    }
      button {
        font-size: 14px;
        padding: 4px;
        /* padding-bottom: 10px; */
        margin-bottom: 4px;
      float: right;
      margin-top: -40px;
      }
    }
  }
}
