thead {
  tr {
    th {
      width: 10%;
    }
  }
}

.payment-modal.modal-dialog {
  max-width: 98%;
  .modal-content {
    .modal-body {
      padding-left: 0%;
      margin-bottom: -2%;
      .hide-button {
        display: none;
      }
    }
  }
}

.payment-section {
  margin-top: -20px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -30px;
  font-size: 13px;

  .payment-form {
    .track {
      margin-left: 0px;
      display: flex;
      width: 245px;
      label {
        text-align: end;
        width: 40%;
        margin-right: 4px;
      }
      > div {
        padding-right: 5px;
        width: 58%;
      }
    }
    @media (max-width: 1250px) {
      .track{
        width: 195px;
      }
      label {
        text-align: end;
        width: 40%;
        margin-right: 2px;
      }
      > div {
        width: 57%;
      }
    }
    .section {
      margin-left: 0px;
      .fields {
        display: flex;
        width: 245px;
        align-items: center;
        label {
          text-align: end;
          margin-right: 4px;
          width: 40%;
        }
        > div {
          padding-right: 5px;
          width: 60%;
          select{
            font-size: 13px;
          }
          input{
            font-size: 15px;
            text-align: center;
            color: black;
          }
        }
      }
      .add-margin{
        margin-left: 245px;
      }
    }

    @media (max-width: 1250px) {
      font-size: 12px;
      .section {
        margin-left: 0px;
        .fields {
          display: flex;
          width: 195px;
          label {
            text-align: end;
            margin-right: 2px;
            width: 40%;
          }
          > div {
            padding-right: 3px;
            width: 60%;
          }
        }
        .add-margin{
          margin-left: 195px;
        }
      }
    }

    .memo-edit-icon {
      position: absolute;
      top: 10%;
      left: -10%;
      cursor: pointer;
    }
    .heading {
      font-size: 16px;
      padding-top: 28px;
    }
    .bold-text-div {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .payment-documents {
    width: 50%;
    padding: 10px;
    .heading {
      //text-align: center;
      font-weight: bold;
      padding: 10px;
      //text-align: center;
      //margin-bottom: 20px;
      margin-top: 10px;
      margin-left: -1%;
    }
  }
}

.other-payment-section {
  padding: 15px;
}

.charges-section {
  .charges-payment-data {
    display: flex;
    justify-content: space-between;
    label {
      text-align: right;
    }
    .charges-payment-data-left {
      width: 40%;
      padding: 10px;
    }
    .charges-payment-data-right {
      width: 50%;
      padding: 10px;
    }
  }
  .payment-rejected {
    padding: 0px 10px 0px 10px;
    margin-top: -1%;
  }
  .payment-comments {
    .status-comment {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  .approval-comment {
    margin-top: 2%;
  }
  .charge-table {
    padding-left: 10px;
    .charges-header {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      margin-top: -40px;

      & > .charges-hoas {
        width: 40%;
        display: flex;
      }
      & > .charges-hoas Label {
        padding: 5px;
      }
      & > .charges-date {
        width: 30%;
        display: flex;
        margin-left: 10px;
      }
      & > .charges-date Label {
        padding: 5px;
      }
      & > .add-charge button {
        margin: 0;
        width: 100%;
      }
      & > .add-charge {
        margin-left: 10px;
        margin-top: -45px;
      }
    }
  }
  .approval-btn {
    //margin-left: 1rem;
    bottom: 2rem;
    position: absolute;
    left: 47%;
  }
}

.tracking {
  width: 9%;
  padding-left: 1.5%;
}
.documentName-label {
  width: 13%;
  padding-left: 1.5%;
}

.payment-approved-input {
  width: 125px;
  input {
    text-align: center;
  }
}

.tracking-inputField {
  width: 28%;
  padding-left: 1.5%;
}

.documentNotes-label {
  width: 11%;
  padding-left: 10px;
}
.documentNotes-inputField {
  width: 24%;
  padding-left: 1.5%;
}

.documentName-inputField {
  padding-left: 0%;
  width: 18%;
}

.payment-information-input {
  width: 10%;
  padding-left: 0.5%;
}
.payment-information-label {
  width: 180px;
  padding-left: 1.5%;
}

.payment-dueDate-label {
  padding-left: 0.5%;
  width: 13%;
}

.invoice-number-label {
  width: 5%;
  padding-left: 0.5%;
}

.invoice-received-label {
  width: 180px;
  padding-left: 0.5%;
}

.invoice-number-input {
  width: 23%;
  padding-left: 0.5%;
}

.heading {
  margin-top: -20px;
  font-weight: bold;
  padding: 10px;
  //margin-bottom: 20px;
}

.payment-info {
  margin-right: 5px;
  margin-left: 10px;
  display: flex;
  .question {
    display: inline-flex;
    width: 20%;
    justify-content: flex-end;
    .radio {
      display: inline-flex;
      .custom-control {
        margin-right: 0px;
      }
    }
    .text {
      // width: 42%;
      text-align: end;
    }
  }
  .payee {
    display: inline-flex;
    width: 42%;
    justify-content: flex-end;
    label {
      margin-right: 10px;
    }
    .select-box {
      width: 400px;
    }
  }
  .other-payee {
    display: inline-flex;
    width: 42%;
    margin-left: 10px;
    justify-content: flex-end;
    label {
      margin-right: 10px;
      width: 160px;
      text-align: end;
    }
    .select-box {
      width: 400px;
    }
  }
  .memo {
    width: 35%;
    justify-content: flex-end;
    display: inline-flex;
    &-head {
      font-weight: 600;
      padding-right: 10px;
      width: 180px;
      text-align: end;
    }
    &-details {
      width: 20em;
      display: flex;
    }
  }

  .reciepent-addr {
    width: 35%;
    justify-content: flex-end;
    display: inline-block;
    .reciepent-addr-data{
      display: flex;
    }
    .care-of{
      display: flex;
      align-items: baseline;
    }
    &-head {
      font-weight: 600;
      padding-right: 10px;
      width: 270px;
      text-align: end;
    }
    &-details {
      width: 20em;
      display: flex;
      align-items: baseline;
    }

  }
}

.settlement-select {
  height: 35;
}

.paymentTable {
  margin-top: -2%;
}

.documentClass {
  padding-left: 2%;
  margin-bottom: 1%;
}

.documentUploadLabel {
  width: 15%;
  padding-left: 21px;
}
.documentUploadInput {
  width: 18%;
}


.nodocumentUpload{
  display: flex;
  width: 20%;
.noDocument {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
}
}
.documentUpload{ 
  width: 8%;
}
.document-table{
  width: 90%;
  //margin-top: -2rem;
  //margin-left: 1rem;
  margin-right: -5rem;
}

.other-acc {
  display: flex;
  justify-content: flex-end;
  margin-right: 68px;
  .acc {
    display: flex;
    .text {
      width: 19em;
    }
  }
  label {
    margin-right: 10px;
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      text-align: center;
    }
  }
}
