
.modal-dialog.vendor-modal{
    min-width: 85%;
}

.add-model-right-portion {
    width: 45%;
    padding: 10px;

.add-poc {
    color: white;
    display: flex;
    border: 1px solid white;
    background: #53bf53;
    border-radius: 20px;
    width: 25%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .text {
      padding: 6px;
    }
  }
}
.poc-style {
    border: 1px solid #ccc;
    padding: 10px 15px;
    margin-bottom: 20px;
  }