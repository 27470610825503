.code {
  margin-bottom: 20px;
}

.aco {
  border: 1px solid #aaa;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.questions {
  .question {
    margin: 10px;
  }

  .radio-buttons {
    display: flex;
  }
}

.acess-code-questions{
  display: flex;
  .access-question {
    margin: 10px;
  }

  .acess-code-radio-buttons {
    margin: 10px;
    display: flex;
  }
}

.access-codes-container {
  width: 100%;
  padding-right: 55px;
  .edit-link {
    position: absolute;
    left: 50%;
  }
  .accesss-codes {
    display: flex;
    .heading {
      font-weight: bold;
      min-width: 200px !important;
    }
  }
  .accesss-codes-values{
    margin-top: -20px;
    padding: 10px;
  }
}
.custom-control {
  margin-right: 10px;
  margin-left: 10px;
}

.access-code-modal.modal-dialog {
  max-width: 85%;

  .modal-content {
    width: 100%;
    padding-left: 0%;
  }

  .control-details {
    padding: 10px;
  }

  .code-notes {
    padding: 0px 10px 0px 10px;
    margin-bottom: 0rem;
  }

  .yes-no-div {
    display: block;
    margin-bottom: 10px;

    .yes-no {
      display: flex;
    }
  }

  .access-form {
    position: relative;
    bottom: 60px;
    width: 45%;
    left: 30%;
    margin-top: 1.5rem;
    .access-file-btn{
    margin-top: 0px !important
    }
  }

  .create-Btn {
    position: absolute;
    bottom: 16px;
    left: 125px;
  }

  .form-heading {
    font-size: 24px;
    font-weight: bold;
  }
}
