.modal-dialog.deedVerification-modal {
  min-width: 95%;
}
.radio-button {
  display: inline-flex;
  //margin-left: 15px;
  .custom-control {
    margin-right: 0px;
    margin-left: 5px;
  }
}

.deed-radio-col{
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}

.deed-radio {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
  margin-top: 0px;
  padding-right: 10px;
  width: 90%;
  // padding: 0px 0px 5px 10px;
  .deed-question {
    display: inline-flex;
    // justify-content: flex-end;
    width: 50%;
    .rightside-content {
      padding-left: 25px;
    }
    .radio-button {
      display: inline-flex;
      margin-left: 15px;
      .custom-control {
        margin-right: 0px;
        margin-left: 5px;
      }
    }
    .deed-text {
      width: 100%;
      text-align: right;
      font-weight: bold;
    }
  }

  .deed-ip-fields {
    position: relative;
    display: inline-flex;
    width: 50%;
    justify-content: flex-end;
    padding: 0px 0px 5px 10px;
    label {
      text-align: right;
      padding-right: 10px;
      align-self: center;
    }
    textarea {
      width: 50%;
    }
    //   div {
    //     align-self: center;
    //     width: 18%;
    //   }
    select {
      width: 45%;
    }
    .customDropdownicon-deedverification {
      color: black;
      position: absolute;
      padding: 9px 16px 0 0;
    }
  }
}

.deed-question {
  display: inline-flex;
  // justify-content: flex-end;
  width: 100%;
  .rightside-content {
    padding-left: 25px;
  }
  .radio-button {
    display: inline-flex;
    margin-left: 15px;
    .custom-control {
      margin-right: 0px;
      margin-left: 5px;
    }
  }
  .deed-text {
    width: 100%;
    text-align: right;
    font-weight: bold;
  }
}

Label {
  text-align: right;
  font-weight: bold;
}

.poa_date {
  width: 45%;
}

.upload_deed_document {
  margin-top: 0px !important;
  margin-bottom: 15px;
}
