.react-bootstrap-table .table {
    /* table-layout: auto !important; */
}

.react-bootstrap-table {
    font-size: 12px !important;
}

.btn-group.actions {
    position: relative;
    float: right;
    margin-top: -4%;
    margin-bottom: 1%;
    right: 0%;
}
.btn-group.addactions {
    position: relative;
    float: right;
    margin-top: -65px;
    margin-bottom: 1%;
    right: 30px;
    width: 75px;
}
.btn-group.actions button {
    border-radius: 5px !important;
    margin-right: 10px;
}

.table-actions button {
    margin-right: 5px;
}

input[type="file"] {
    height: 58px;
}