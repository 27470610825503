.inspections {
  &-info {
    width: 55%;

    button {
      float: right;
      position: relative;
      bottom: 45px;
      left: 15%;
      border-radius: 4px;
      width: 70px;
      height: 35px;
      border: none;
      color: white;
      background: gray;
    }
  }

  &-intervals {
    .intervals-info {
      display: flex;
      width: 100%;
      padding: 15px;

      button {
        position: relative;
        left: 2%;
        top: 5px;
        border-radius: 4px;
        width: 70px;
        height: 35px;
        border: none;
        color: white;
        background: gray;
      }

      .heading {
        padding: 10px;
        width: 25%;
      }

      .select-box {
        width: 30%;
      }
    }
  }
}

.row-items {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.right-align {
  white-space: nowrap;
  text-align: right;
  margin-top: 10px;
}

.text-yes {
  margin-left: 5px;
  margin-right: 10px;
}
.text-no {
  margin-left: 5px;
  margin-right: 10px;
}

.date-width {
  width: 120px;
  margin-top: -5px;
}

.dropdown-date-row-align {
  display: flex;
  flex-direction: row;margin-left: 10px;
}

input[type="radio"].radio-button {
  border: 0px;
  width: 15px;
  height: 15px;
  .custom-control {
    margin-right: 0px;
  }
}

.select-dropdown-width {
  width: 200px;
  margin-right: 20px;
}

.display-in-same-row {
  display: flex;
  margin-top: 10px;
}
.submit-button-alignment {
  float: right;
  margin-top: 30%;
}

.inspection-radio {
  display: inline-flex;
  margin-top: 10px;
  .custom-control {
    margin-right: 0px;
  }
}