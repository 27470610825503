.dashboard_scope_div{
    width: 100%;
    display: flex;
    margin: 5px;
    .dashboard_scope_checkbox_div{
        display: flex;
        width: 30%;
    }
  .checkbox_input{
       width: 50px;
    margin-left: 10px;
}
.checkbox_div_data{
    margin: 5px;
    width: 300px;
    font-size: 16px !important;
}}