.modal-dialog.association-modal {
  min-width: 90%;
}
.pos {
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: absolute;
  bottom: 16vh;
  left: 40%;
  label {
    padding-right: 10px;
    align-self: center;
  }
  div {
    padding-right: 10px;
  }
}
.status {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  div {
    padding-right: 10px;
  }
}
.poc {
  width: 100%;
  float: right;
  .ph {
    display: flex;
    justify-content: flex-end;
  }
  .ip-fields {
    display: flex;
    justify-content: flex-end;
    padding: 7px 25px 7px 10px;
    input {
      width: 440px;
    }
    label {
      padding-right: 10px;
      align-self: center;
      text-align: right;
    }
    div {
      align-self: center;
    }
    #ext2 {
      width: 100px;
      // margin-right: -12px;
    }
    #phone2 {
      width: 264px;
    }
    #ext3 {
      width: 100px;
      // margin-right: -12px;
    }
    #phone3 {
      width: 264px;
    }
  }
}
.code-radio {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-right: 10px;
  .question {
    display: inline-flex;
    // justify-content: flex-end;
    width: 50%;
    .rightside-content {
      padding-left: 25px;
    }
    .radio {
      display: inline-flex;
      .custom-control {
        margin-right: 0px;
      }
    }
    .text {
      width: 100%;
      text-align: right;
    }
  }
  
  .ip-fields {
    display: inline-flex;
    width: 50%;
    justify-content: flex-end;
    padding: 0px 20px 5px 10px;
    label {
      text-align: right;
      padding-right: 10px;
      align-self: center;
    }
    div {
      align-self: center;
    }
    #point_of_contact_1 {
      width: 440px;
    }
    #email1 {
      width: 440px;
    }
  }
}

.next-section {
  display: flex;
  .ques {
    width: 50%;
    .question {
      display: flex;
      .text {
        width: 100%;
        text-align: right;
      }
      .radio {
        display: inline-flex;
        .custom-control {
          margin-right: 0px;
        }
      }
    }
    .sel{
      display: flex;
      justify-content: flex-end;
      align-items:center;
      padding: 5px 0px 5px 0px;
      label{
        padding-right: 10px;
      }
    }
    .ip-fields {
      display: flex;
      justify-content: flex-end;
      padding: 5px 0px 5px 0px;
      label {
        padding-right: 10px;
      }
      div {
        width: 450px;
        @media screen and (max-width: 780px) {
          width:390px;
        }
      }
     
    }
    .tel {
      display: flex;
      justify-content: flex-end;
      padding: 5px 0px 5px 0px;
     .Phone{
      display: flex;
      div{
        padding-left: 10px;
      }
      width: 315px;
      @media screen and (max-width: 780px) {
        width:260px;
      }
      // #ext1{
      //   width: 160px;
      // }
      // #ext2{
      //   width: 160px;
      // }
      // #ext3{
      //   width: 160px;
      // }
     }
     .Ext{
      width: 190px;
       display: flex;
       label{
         padding-left: 10px;
       }
       div{
        padding-left: 10px;
      }
     }
    }
  }
  .hoa {
    width: 50%;
    .question{
      display: flex;
      padding: 15px 0px 5px 0px;
      justify-content: flex-end;
      .radio{
        display: flex;
        .custom-control {
          margin-right: 0px;
        }
      }
    }
    .sel{
      display: flex;
      justify-content: flex-end;
      align-items:center;
      padding: 5px 0px 5px 0px;
      label{
        padding-right: 10px;
      }
    }
    .ip-fields {
      display: flex;
      justify-content: flex-end;
      padding: 5px 0px 5px 0px;
      label {
        padding-right: 10px;
      }
      div {
        width: 450px;
      }
    }
    .tel {
      display: flex;
      justify-content: flex-end;
      padding: 5px 0px 5px 0px;
     .Phone{
      display: flex;
      div{
        padding-left: 10px;
      }
      width: 315px;
      // #ext1{
      //   width: 160px;
      // }
      // #ext2{
      //   width: 160px;
      // }
      // #ext3{
      //   width: 160px;
      // }
     }
     .Ext{
      width: 190px;
       display: flex;
       label{
         padding-left: 10px;
       }
       div{
        padding-left: 10px;
      }
     }
    }
  }
}

.nextQuestion{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px;
  // padding-right: 10px;
  .question {
    display: inline-flex;
    // justify-content: flex-end;
    width: 50%;
    .rightside-content {
      padding-left: 25px;
    }
    .radio {
      display: inline-flex;
      .custom-control {
        margin-right: 0px;
      }
    }
    .text {
      width: 100%;
      text-align: right;
    }
  }
.sel{
  display: flex;
  justify-content: flex-end;
  align-items:center;
  padding: 5px 0px 5px 0px;
  label{
    padding-right: 10px;
  }
}

.ip-fields {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0px 5px 0px;
  label {
    padding-right: 10px;
  }
  div {
    width: 450px;
  }
}

}

.file-upload{
  margin-left: 11rem; 
  margin-bottom: 2rem;
  display: flex;
  .noDocument {
    margin-top: 20px;
    margin-left: 10px;
  }
}

.association-document-modal{
  display: flex;
  justify-content: space-between; 
  margin-top: -2rem;
  margin-left: 14rem;
}
