.modal-dialog.businessUnit-modal {
    min-width: 50%;
  } 
  

  .add-business-model-poc {
    width: 100%;
    padding: 10px;

  
  .add-poc {
    color: white;
    display: flex;
    border: 1px solid white;
    background: #53bf53;
    border-radius: 20px;
    width: 25%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .text {
      padding: 6px;
    }
    
  }
  
}

.location:hover {
color: blue;
text-decoration: underline;
}
