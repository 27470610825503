.flex-container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
  width: 70%;
}

.flex-container > div {
  background-color: #00aff0;
  width: 15%;
  margin: 7px;
  text-align: center;
  // line-height: 30px;
  font-size: 18px !important;
  // border: 1px solid;
  padding: 5px;
  box-shadow: 5px 5px #888888;
  //font-weight: bold;
  color: white;
  border-radius: 5px;
}

.flex-heading {
  font-size: 16px !important;
}

.horizontalClass {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0.5px solid white !important;
}
.card-firstRow{
  cursor: pointer;
}

.middleRow {
  display: flex;
 height: 33px;
  justify-content: center;
  align-items: center;
  .firstRowData {
    width: 50%;
    cursor: pointer;
    border-right: 0.5px solid white;
  }
  .secondRowData {
    width: 50%;
    cursor: pointer;
  }
}

.tierTwoClass {
  width: 95%;
  margin-top: 2%;
  // margin-left: 10%;
  //border: 1px solid;
  text-align: center;
}

// .tierTwoClass > div{  border: 1px solid;}

.tierTwoDivClass {
  border: 1px solid;
  display: flex;
  .association {
    width: 20%;
    border-right: 1px solid;
    .firstRow {
      font-size: 1.5rem;
      border-bottom: 1px solid;
      cursor: pointer;
    }
    .firstRow:hover {
      font-size: 1.5rem;
      border-bottom: 1px solid;
      cursor: pointer;
      background-color: blue !important;
    }
    .middleRow {
      display: flex;
     height: 33px;
      .firstRowData {
        width: 50%;
        // border-top: 1px solid;
        // border-bottom: 1px solid;
        font-size: 1.5rem;
        margin-top: 7px;
        cursor: pointer;
      }
      .firstRowData:hover {
        width: 50%;
        // border-top: 1px solid;
        // border-bottom: 1px solid;
        font-size: 1.5rem;
        margin-top: 7px;
        cursor: pointer;
        background-color: blue !important;
      }
      .secondRowData {
        width: 50%;
        // border-top: 1px solid;
        // border-bottom: 1px solid;
        font-size: 1.5rem;
        border-left: 1px solid;
        margin-top: 7px;
        cursor: pointer;
      }
      .secondRowData:hover {
        width: 50%;
        // border-top: 1px solid;
        // border-bottom: 1px solid;
        font-size: 1.5rem;
        border-left: 1px solid;
        margin-top: 7px;
        cursor: pointer;
        background-color: blue !important;
      }
    }
    .thirdRow {
      border-top: 1px solid;
      font-weight: bold;
      height: 30px;
      font-size: 13px !important;
    }
    .association {
      border-top: 1px solid;
    }
  }
}

.associationReview {
  background-color: gray;
  border-top: 0px !important;
  border: 1px solid;
  color: white;
}

.tierThreeClass {
  width: 95%;
  margin-top: 2%;
  // margin-left: 20%;
  //border: 1px solid;
  text-align: center;
}

// .tierTwoClass > div{  border: 1px solid;}

.tierThreeDivClass {
  border: 1px solid;
  display: flex;
  .association {
    width: 20%;
    border-right: 1px solid;
    .firstRow {
      font-size: 1.5rem;
      border-bottom: 1px solid;
      cursor: pointer;
    }
    .middleRow {
      display: flex;
     height: 33px;
      .firstRowData {
        width: 50%;
        // border-top: 1px solid;
        // border-bottom: 1px solid;
        font-size: 1.5rem;
        //  margin-top: 7px;
        // cursor: pointer;
      }
      .secondRowData {
        width: 50%;
        // border-top: 1px solid;
        // border-bottom: 1px solid;
        font-size: 1.5rem;
        border-left: 1px solid;
        //  margin-top: 7px;
        //cursor: pointer;
      }
    }
    .thirdRow {
      border-top: 1px solid;
      font-weight: bold;
      height: 30px;
      font-size: 13px !important;
    }
    .association {
      border-top: 1px solid;
    }
  }
}
