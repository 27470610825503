//new css
.amenity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-right: 10px;
  .amenity-ques-left {
    display: flex;
    width: 32%;
    .radio {
      display: inline-flex;
      .custom-control {
        margin-right: 0px;
      }
    }
    .text {
      width: 100%;
      text-align: right;
    }
  }
  .amenity-ques-right {
    display: flex;
     padding-right: 6%;
     width: 50%;
    .radio {
      display: inline-flex;
      .custom-control {
        margin-right: 0px;
      }
    }
    .text {
      width: 100%;
      text-align: right;
    }
    .mailbox_label{
     position: absolute;
    text-align: right;
    }
    .mailbox_radio{
      display: flex;
      position:relative;
      left:400px;
    }
  }

  .amenities-file-upload-noDocument{
    display: flex;
    //padding-right: 10%;
    margin-left: 19rem;
  .amenities-file-upload {
    display: flex;
    .file-upload-label {
      white-space: nowrap;
      margin-right: 1rem;
    }
    .noDocument {
      margin-top: 10px;
     
    }
    .no-file{
      display: flex;
    }
    .file-btn {
      margin-top: 0px !important;
    margin-bottom: 0px !important;
     
    }
  }
}
  .amenity-ques-right-file{
    display: flex;
   // padding-right: 19%;
   margin-left: 13rem;
  .amenities-file-upload {
    display: flex;
   // margin-left: 5rem;  
    
    .noDocument {
      margin-top: 10px;
      margin-left: 10px;
      white-space: nowrap;
    }
    .file-upload-label {
      white-space: nowrap;
      margin-right: 1rem;
    }
    .file-btn {
      margin-top: 0px !important;
    margin-bottom: 0px !important;
     
    }

    // .react-bootstrap-table{
    //   position: absolute;
    //   top: 700px;
    //   right: 100px;
    // }
  }
}
}
.amenities-document-modal{
  margin-top: -3rem;
}

