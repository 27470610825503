.modal-dialog.law-firm-modal {
    min-width: 90%;
  }
  
  .poc-style {
    border: 1px solid #ccc;
    padding: 10px 15px;
    margin-bottom: 20px;
  }
  
  .add-law_firm {
    display: flex;
    justify-content: space-between;
  
    .add-model-left-portion {
      width: 60%;
      padding: 10px;
    }
  
    .add-model-right-portion {
      width: 40%;
      padding: 10px;
    }
    .add-poc {
      color: white;
      display: flex;
      border: 1px solid white;
      background: #53bf53;
      border-radius: 20px;
      width: 25%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .text {
        padding: 6px;
      }
    }
  }
  